import React, { useState } from 'react';
import cascading from '../assests/scss/all.module.scss'

function Timercount(props) {

    return (
        <>
            <div className={`${cascading.timercount} row`}>
                <div className={`col-lg-3 col-md-6 col-sm-6 mb-4`}>
                <div className={`${cascading.timercard} ${cascading.dayscard}`}>
                    <div className={`${cascading.topsec}`}>
                       <p className={`${cascading.count}`}> {props.days}</p>
                    </div>
                    <div className={`${cascading.btmsec}`}>
                         <p className={`${cascading.label}`}>Days</p>
                    </div>

                </div>
                </div>
             
                {/* <p className={`${cascading.ratio}`}>:</p> */}
                <div className={`col-lg-3 col-md-6 col-sm-6 mb-4`}>
                <div className={`${cascading.timercard} ${cascading.hourscard}`}>
                    <div className={`${cascading.topsec}`}>
                       <p className={`${cascading.count}`}> {props.hours}</p>
                    </div>
                    <div className={`${cascading.btmsec}`}>
                         <p className={`${cascading.label}`}>Hours</p>
                    </div>

                </div>
                </div>
                {/* <p className={`${cascading.ratio}`}>:</p> */}
                <div className={`col-lg-3 col-md-6 col-sm-6 mb-4`}>
                <div className={`${cascading.timercard} ${cascading.minutecard}`}>
                    <div className={`${cascading.topsec}`}>
                       <p className={`${cascading.count}`}> {props.minutes}</p>
                    </div>
                    <div className={`${cascading.btmsec}`}>
                         <p className={`${cascading.label}`}>Minutes</p>
                    </div>

                </div>
                </div>

                {/* <p className={`${cascading.ratio}`}>:</p> */}
                <div className={`col-lg-3 col-md-6 col-sm-6 mb-4`}>
                <div className={`${cascading.timercard} ${cascading.secondcard}`}>
                    <div className={`${cascading.topsec}`}>
                       <p className={`${cascading.count}`}> {props.seconds}</p>
                    </div>
                    <div className={`${cascading.btmsec}`}>
                         <p className={`${cascading.label}`}>Seconds</p>
                    </div>

                </div>
                </div>

            </div>
        </>


    )
}

export default Timercount