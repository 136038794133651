import Landingpage from "../pages/Landingpage";
import Community from "../pages/community";
import Tradingrank from "../pages/tradingrank";
import Tradinginformation from "../pages/tradinginformation";
import Blog from "../pages/blog";
import Servertimer from "../pages/servertimer";
import Gasbalance from "../pages/gasbalance";
import TransactionDetails from "../pages/TransactionDetails";
import Mpayment from "../pages/Mpayment";
import EXpayment from "../pages/EXpayment";
import Memberinfo from "../pages/memberinfo";
import Notices from "../pages/notices";
import Inquiry from "../pages/inquiry";
import Useguide from "../pages/useguide";
import Faq from "../pages/faq";
import Commission from "../pages/Commission";
import Aisettings from "../pages/aisettings";
import Apisetting from "../pages/Apisetting";
import Airdrop from "../pages/Airdrop";
import Subscription from "../pages/Subscription";
import Aistatus from "../pages/Aistatus";
import Memberdashboard from "../pages/memberdashboard";
import Totaldashboard from "../pages/totaldashboard";

const routes = [{
    path: '/',
    component: <Landingpage/>,
  },

  {
    path: '/community',
    component: <Community/>,
  },
  {
    path: '/tradingrank',
    component: <Tradingrank/>,
  },
  {
    path: '/blog',
    component: <Blog/>,
  },
  {
    path:'/aitradingstatus',
    component:<Aistatus/>
},
  {
    path: '/information',
    component: <Tradinginformation/>,
  },
  {
    path: '/servertimer',
    component: <Servertimer/>,
  },
  {
    path: '/gasbalance',
    component: <Gasbalance/>,
  },
  { 
    path: '/Member-PnL-dashboard',
    component: <Memberdashboard/>,
  },
  {
    path: '/transaction-details',
    component: <TransactionDetails/>,
  },
  { 
    path: '/total-PnL-dashboard',
    component: <Totaldashboard/>,
  },
  {
    path: '/signaltrading',
    component: <Airdrop/>,
  },
  {
    path: '/m-payment',
    component: <Mpayment/>,
  },
  {
    path: '/ex-payment',
    component: <EXpayment/>,
  },
  {
    path: '/commission',
    component: <Commission/>,
  },
  {
    path: '/Member-info',
    component: <Memberinfo/>,
  },
  {
    path: '/api-settings',
    component: <Apisetting/>,
  },
  {
    path: '/ai-settings',
    component: <Aisettings/>,
  },
  {
    path: '/subscription',
    component: <Subscription/>,
  },
  
  {
    path: '/faq',
    component: <Faq/>,
  },
  {
    path: '/useguide',
    component: <Useguide/>,
  },
  {
    path: '/inquiry',
    component: <Inquiry/>,
  },

  {
    path: '/notice',
    component: <Notices/>,
  },






 ];
 
 export default routes;