import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Accordion from 'react-bootstrap/Accordion';
import cascading from '../assests/scss/landing.module.scss';
import cardblur from '../assests/images/home/cardshadow.png';
import arrowicon from '../assests/images/home/arrowicon.png';
import botimg from '../assests/images/home/botimg.png';
import linearhr from '../assests/images/home/hrlinestyle.png';
import adbannerimg from '../assests/images/home/ad_banner.png';
import goldline from '../assests/images/home/goldline.png';
import swapimg from '../assests/images/home/swapimg.png';
import faqimg from '../assests/images/home/faqsec.png';
import twitterimg from '../assests/images/twitter.png';
import telegramimg from '../assests/images/telegram.png';
import discordimg from '../assests/images/discord.png';
import bannerpattern from '../assests/images/home/bannerpattern.png';
import shadoweffect from '../assests/images/home/shadoweffect.png'

import { FaArrowRightLong } from "react-icons/fa6";
import Footer from '../common/footer';
import Header from '../common/header';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
function Landingpage() {
  const data = useSelector((state) => state.admin)
  const [sociallist, setSociallist] = useState([{
    img: telegramimg,
    head: 'Telegram'

  },
  {
    img: twitterimg,
    head: 'Twitter'

  },
  {
    img: discordimg,
    head: 'Discord'

  }])
  const options = {
    items: 3,
    dots: true,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2
      },
      1200: {
        items: 3
      }
    }


  };
  return (
    <div className={`${cascading.landingpage}`}>
      <Header />
      <section className={`${cascading.bannersec}`}>
        <img src={shadoweffect} className={`img-fluid ${cascading.shadowblur}`} alt='shadow' />
        <img src={bannerpattern} className={`img-fluid ${cascading.rightsideimg}`} alt='img' />
        <img src={bannerpattern} className={`img-fluid ${cascading.leftsideimg}`} alt='img' />
        <p className={`${cascading.head}`}>Lorem ipsum dolor sit amet!</p>
        <p className={`${cascading.desc}`}>Integer efficitur magna at nibh aliquet, sed mattis purus pulvinar.</p>
        <div className={`${cascading.btnsec}`}>
          {data.loginStatus == "login" ?
            <Link to="/community" className={`btn ${cascading.themebtn}`}>Get Started</Link>
            :
            <button to="/community" className={`btn ${cascading.themebtn}`} onClick={()=> toast.warning("Please Login")}>Get Started</button>
          }

          {/* <button className={`btn ${cascading.themebtn1}`}>Get Started</button> */}
        </div>

      </section>
      <section className={`${cascading.cardsec}`}>
        <img src={shadoweffect} className={`img-fluid ${cascading.shadowblur}`} alt='shadow' />
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-lg-6`}>
              <p className={`${cascading.head}`}>
                Nullam sit amet iaculis arcu
                ac rhoncus massa consectetur amet.
              </p>
            </div>
            <div className={`col-lg-6`}>
              <p className={`${cascading.desc}`}>
                Nullam sed diam vitae metus volutpat cursus. Proin hendrerit venenatis mauris
                ac scelerisque. Nulla eget orci tellus. Quisque aliquet dictum libero at auctor.
              </p>
            </div>

          </div>
          <div className={`row ${cascading.cardrow} mt-4`}>
            {Array.from(Array(6).keys(1)).map(() =>
              <div className={`col-lg-4 col-md-6 mb-4`}>
                <div className={`${cascading.cardstyle}`}>
                  <img src={cardblur} alt='cardblur' className={`img-fluid ${cascading.cardshadow}`} />
                  <div>
                    <p className={`${cascading.head}`}>Pellentesque id purus leo. Proin
                      dapibus, nunc in posuere</p>
                    <p className={`${cascading.desc}`}>Nullam sed diam vitae metus volutpat cursus. Proin hendrerit venenatis mauris ac scelerisque. Nulla eget orci tellus. Quisque aliquet dictum libero at auctor.</p>
                  </div>
                  <div className={`${cascading.readmoresec}`}>
                    <Link to="" className={`${cascading.readbtn}`}>Read More</Link>
                    <img src={arrowicon} className={`img-fluid ${cascading.arrowimg}`} alt='arrow' />

                  </div>

                </div>

              </div>
            )
            }





          </div>

        </div>

      </section>
      <section className={`${cascading.getsec}`}>
        <img src={shadoweffect} className={`img-fluid ${cascading.shadowblur}`} alt='shadow' />
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-12`}>
              <div className={`${cascading.contentsec}`}>
                <p className={`${cascading.head}`}>Morbi mollis diam a ante aliquet, in
                  sollicitudin quam tempor.</p>
                <p className={`${cascading.desc}`}>Nullam sed diam vitae metus volutpat cursus. Proin hendrerit venenatis mauris
                  ac scelerisque. Nulla eget orci tellus. Quisque aliquet dictum libero at auctor.</p>
                <div className={`${cascading.btnsec}`}>
                {data.loginStatus == "login" ?
                  <Link to="/community" className={`btn ${cascading.startedbtn}`} >Get Started Now
                    <div className={`${cascading.arrowsec}`}>
                      <FaArrowRightLong />
                    </div>
                  </Link>
                  :
                  <button className={`btn ${cascading.startedbtn}`} onClick={()=> toast.warning("Please Login")}>Get Started Now
                    <div className={`${cascading.arrowsec}`}>
                      <FaArrowRightLong />
                    </div>
                  </button>
}
                </div>

              </div>

            </div>

          </div>

        </div>

      </section>
      <section className={`${cascading.gridcardsec}`}>
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-lg-5 mt-5`}>

              <p className={`${cascading.sechead}`}>Morbi mollis diam a ante
                aliquet, in sollicitudin.</p>
              <p className={`${cascading.secdesc}`}>Nullam sed diam vitae metus volutpat cursus. Proin Aenean gravida ut tellus a mattis hendrerit venenatis mauris ac scelerisque.</p>
              <div className={`row ${cascading.leftcardsec}`}>
                <div className={`col-lg-8 mb-4 ${cascading.colcard} mt-5`}>

                  <div className={`${cascading.gridcardstyle}`}>
                    <img src={botimg} alt='icon' className={`img-fluid ${cascading.icon}`} />
                    <p className={`${cascading.head}`}>Nulla eget</p>
                    <img src={linearhr} className={`img-fluid ${cascading.linearline} mb-3`} alt='linear' />
                    <p className={`${cascading.desc}`}>
                      Nullam sed diam vitae metus volutpat cursus. Proin hendrerit venenatis mauris ac scelerisque. Nulla eget orci tellus. Quisque aliquet dictum libero at auctor.
                    </p>

                  </div>
                </div>
              </div>

            </div>
            <div className={`col-lg-7`}>
              <div className={`row  ${cascading.cardsec}`}>
                {Array.from(Array(4).keys(1)).map(() => (
                  <div className={`col-md-6 mb-4 ${cascading.colcard}`}>

                    <div className={`${cascading.gridcardstyle}`}>
                      <img src={botimg} alt='icon' className={`img-fluid ${cascading.icon}`} />
                      <p className={`${cascading.head}`}>Nulla eget</p>
                      <img src={linearhr} className={`img-fluid ${cascading.linearline} mb-3`} alt='linear' />
                      <p className={`${cascading.desc}`}>
                        Nullam sed diam vitae metus volutpat cursus. Proin hendrerit venenatis mauris ac scelerisque. Nulla eget orci tellus. Quisque aliquet dictum libero at auctor.
                      </p>

                    </div>
                  </div>))}

              </div>



            </div>

          </div>

        </div>

      </section>
      <section className={`${cascading.adbanersec}`}>
        <div className={`container`}>
          <div className={`${cascading.adbanercard}`}>
            <div className={`row`}>
              <div className={`col-lg-5 ${cascading.leftsec}`}>
                <img src={adbannerimg} className={`img-fluid ${cascading.adimg}`} alt='adbanner' />

              </div>
              <div className={`col-lg-7`}>
                <div className={`${cascading.contentsec}`}>
                  <p className={`${cascading.head} mt-4`}>Morbi mollis diam </p>
                  <p className={`${cascading.desc} my-4`}>Nullam sed diam vitae metus volutpat cursus. Proin hendrerit venenatis mauris
                    ac scelerisque. Nulla eget orci tellus. Quisque aliquet dictum libero at auctor.</p>
                  <div className={`${cascading.btnsec}`}>
                    
                    <button className={`btn ${cascading.startedbtn}`} onClick={()=> toast.warning("Please Login")}>Get Started Now
                      <div className={`${cascading.arrowsec}`}>
                        <FaArrowRightLong />
                      </div>
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>


        </div>

      </section>
      <section className={`${cascading.stepcardsec}`}>
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-12`}>
              <div className={`${cascading.stepheadcard}`}>
                <p className={`${cascading.desc}`}>Get Started with simple steps</p>
                <FaArrowRightLong />
              </div>
            </div>
          </div>
          <div className={`${cascading.gridrow} mt-4`}>
            {Array.from(Array(5).keys(1)).map((i) => (
              <div className={`${cascading.cardstyle}`}>


                <div className={`${cascading.topheadrow}`}>
                  {i == 0 &&
                    <img src={swapimg} className={`img-fluid ${cascading.swapicon}`} alt='swap' />
                  }
                  <p className={`${cascading.head}`}>Pellentesque id purus leo. Proin
                    dapibus, nunc in posuere</p>
                  <p className={`${cascading.desc}`}>Nullam sed diam vitae metus volutpat cursus. Proin hendrerit venenatis mauris ac scelerisque. Nulla eget orci tellus. Quisque aliquet dictum libero at auctor.</p>
                </div>
                <div className={`${cascading.btmrow}`}>

                  <img src={goldline} className={`img-fluid mb-3 ${cascading.goldimg}`} alt='goldline' />
                  <div className={`${cascading.readmoresec}`}>

                    <Link to="" className={`${cascading.readbtn}`}>Read More</Link>
                    <img src={arrowicon} className={`img-fluid ${cascading.arrowimg}`} alt='arrow' />

                  </div>
                </div>

              </div>
            ))}

          </div>

        </div>

      </section>
      <section className={`${cascading.roadmapsec}`}>
        <img src={shadoweffect} className={`img-fluid ${cascading.shadowblur}`} alt='shadow' />
        <div className={`container`}>
          <p className={`${cascading.sechead}`}>ROADMAP</p>
          <div className={`${cascading.roadslide}`}>
            <div className={`${cascading.lineylw}`}></div>
            <OwlCarousel className='owl-theme' loop margin={10} nav {...options}>
              {Array.from(Array(6).keys(1)).map((i) => (
                <div class='item'>
                  <div className={`${cascading.roadcard}`}>
                    <p className={cascading.head}>Pellentesque id </p>
                    <div className={`${cascading.circle}`}>

                    </div>
                    <p className={cascading.desc}>Nullam sed diam vitae metus volutpat cursus. Proin hendrerit venenatis mauris ac scelerisque. Nulla eget orci tellus. Quisque aliquet dictum libero at auctor.</p>

                  </div>
                </div>
              ))}
            </OwlCarousel>

          </div>


        </div>

      </section>
      <section className={`${cascading.faqsec}`}>
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-lg-1 ${cascading.leftimgsec}`}>
              <img src={faqimg} className={`img-fluid d-none d-lg-block ${cascading.faqimg}`} />
              {/* <p className={`${cascading.sechead}`}>FAQ</p> */}

            </div>
            <div className={`col-lg-11`}>
              <Accordion defaultActiveKey="0" className={`${cascading.accordionstyle}`}>
                {Array.from(Array(4).keys(1)).map((i) => (
                  <Accordion.Item eventKey={i}>
                    <Accordion.Header>Vivamus venenatis lacus sit amet odio feugiat blandit ?</Accordion.Header>
                    <Accordion.Body>
                      Pellentesque id purus leo. Proin dapibus, nunc in posuere suscipit, ipsum metus hendrerit nisl, nec gravida leo quam eget tortor. Proin in eleifend tortor. Aenean porta egestas urna, in ornare purus malesuada quis. Quisque odio nibh, efficitur eu euismod eu, pulvinar ac nibh. Nulla lacinia convallis molestie.

                      Aenean luctus diam nec massa tincidunt faucibus. Maecenas sollicitudin nulla eu pretium efficitur. Ut id felis metus. Proin vel malesuada tortor, vitae laoreet leo. Sed scelerisque nulla in mauris blandit, eget efficitur diam pharetra.Duis euismod in felis mollis pulvinar. Maecenas pellentesque blandit leo, ut congue metus dapibus non.
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>

          </div>
        </div>

      </section>
      <section className={`${cascading.socialsec}`}>
        <div className={`container`}>
          <div className={`row `}>
            <div className={`${cascading.toprow}`}>
              <div>
                <p className={`${cascading.head}`}>Stay connected</p>
              </div>
              <div className={`${cascading.inputsec}`}>
                <input type="text" className={`form-control`} placeholder="Your Email" aria-label="Username" aria-describedby="basic-addon1" />
                <div className={`${cascading.arrowsec}`}>
                  <FaArrowRightLong />
                </div>
              </div>
            </div>


          </div>
          <div className={`row`}>
            {sociallist.map((e) => (
              <div className={`col-lg-4 col-md-6 mb-4`}>
                <div className={`${cascading.socialcard}`}>
                  <img src={e.img} className={`img-fluid ${cascading.socialimg}`} alt='socialicon' />
                  <div>
                    <Link className={`${cascading.sociallink}`} to="#">
                      {e.head}
                      <FaArrowRightLong />
                    </Link>
                  </div>

                </div>

              </div>
            ))}


          </div>

        </div>


      </section>
      <Footer />

    </div>
  )
}

export default Landingpage