import { useSelector, useDispatch } from 'react-redux';

export default function StyleDataGrid() {
    const theme = useSelector((state) => state.admin.themeStatus)
    console.log(theme,"setdetail");
    return {
        backgroundColor: theme == "light" ? "#EFF2F6" : " #1E1810",
        border: 0,
        color: '#DBCEB5',
        fontWeight: 400,
        fontFamily: [
          "Titillium Web"
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
  
        '& .MuiDataGrid-columnsContainer': {
          backgroundColor: '#fafafa',
        },
  
        '& .MuiDataGrid-iconSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
          // borderRight: "1px solid #f0f0f0 "
          color: "#F5C246",
          borderRight: "1px solid transparent ",
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
          borderBottom: "1px solid transparent ",
          justifyContent: "center",
        },
        '& .MuiDataGrid-cell': {
          color:
            'rgba(0,0,0,.85)',
            borderBottom:" 0.5px solid #90670066"
  
        },
        '& .MuiPaginationItem-root': {
          borderRadius: 0,
        },
        '& .MuiDataGrid-columnHeaders': {
          background: theme == "light" ?  "#FAC74933": "#C8AD7929",
          borderBottom: theme == "light" ?  "none" : "1px solid #3D3728 ",
        },
        '& .MuiDataGrid-footerContainer': {
          display: "flex",
          justifyContent: "end",
          borderColor: "#3D3728", 
          border: theme == "light" && "none"
        },
        '& .MuiDataGrid-cellContent': {
            color: theme == "light" ?  "#906700": "#DBCEB5",
            fontWeight:theme == "light" ? 600 : 400
        },
        '& .MuiPaginationItem-text': {
          color: theme == "light" ?  "#906700": "#DBCEB5"
        },
        "& ::-webkit-scrollbar": {
          height: '7px'
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
          justifyContent: "center",
        },
        '&  .MuiDataGrid-columnHeaderTitle ': {
            color: theme == "light" ?  "#906700": "#F5C246",
            fontWeight:theme == "light" ? 600 : 400
          },
      
  
  
  
      }
}
