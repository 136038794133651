import { createSlice } from "@reduxjs/toolkit";


export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    loginStatus: localStorage.getItem('loginstatus') === undefined ? 'logout' : localStorage.getItem('loginstatus'),
    themeStatus: localStorage.getItem('theme') === null ? 'dark' : localStorage.getItem('theme'),

  },
  reducers: {
    Statuses: (state, action) => {
      return (
        { ...state, ...action.payload }
      )
    },
    loginControl: (state, action) => {
      state.loginStatus = action.payload

    },
 


  }

})
export const { loginControl, Statuses } = adminSlice.actions
export default adminSlice.reducer