import React, { useEffect, useState } from 'react'
import cascading from '../assests/scss/sidebar.module.scss'
import logo from '../assests/images/logo.png';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { loginControl } from '../Redux/action';
import { useSelector, useDispatch } from 'react-redux';
import { IoClose } from "react-icons/io5";
import { MdMenu } from "react-icons/md";
import goldline from '../assests/images/goldline.png'
import { toast } from 'react-toastify';
function Sidebar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState();
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  const [mbmenu, setMbmenu] = useState(false);
  const [sidemenu, setSidemenu] = useState([ {
    name: 'Trading Rank',
    path: "/tradingrank",
    img: require('../assests/images/sidebar/rank.png'),
  },
  {
    name: 'Blog',
    path: "/blog",
    img: require('../assests/images/sidebar/blog.png'),
  },
  {
    name: 'AI Trading Status',
    path: "/aitradingstatus",
    img: require('../assests/images/sidebar/status.png'),
  },
  {
    name: 'Trading Information',
    path: "/information",
    img: require('../assests/images/sidebar/information.png'),
  },
  {
    name: 'Server Timer',
    path: "/servertimer",
    img: require('../assests/images/sidebar/server.png'),
  },
  {
    name: 'Trading Gas Balance',
    path: "/gasbalance",
    img: require('../assests/images/sidebar/gas.png'),
  },
  {
    name: 'Member PnL Dashboard',
    path: "/Member-PnL-dashboard",
    img: require('../assests/images/sidebar/dashboard.png'),
  },
  {
    name: 'Transaction Details',
    path: "/transaction-details",
    img: require('../assests/images/sidebar/transaction.png'),
  },
  {
    name: 'Total PnL Dashboard',
    path: "/total-PnL-dashboard",
    img: require('../assests/images/sidebar/mtrading.png'),
  },
  {
    name: 'Signal Trading',
    path: "/signaltrading",
    img: require('../assests/images/sidebar/airdrop.png'),
  },
  {
    name: 'M-Deposit & Withdraw',
    path: "/m-payment",
    img: require('../assests/images/sidebar/deposit.png'),
  },
  {
    name: 'Ex-Deposit & Withdraw',
    path: "/ex-payment",
    img: require('../assests/images/sidebar/withdraw.png'),
  },
  {
    name: 'Recommended Commission',
    path: "/commission",
    img: require('../assests/images/sidebar/recommend.png'),
  },
  {
    name: 'Member Information',
    path: "/Member-info",
    img: require('../assests/images/sidebar/info.png'),
  },
  {
    name: 'API Settings',
    path: "/api-settings",
    img: require('../assests/images/sidebar/api.png'),
  },
  {
    name: 'Trading AI Settings',
    path: "/ai-settings",
    img: require('../assests/images/sidebar/aisetting.png'),
  },
  {
    name: 'Product subscription',
    path: "/subscription",
    img: require('../assests/images/sidebar/subscription.png'),
  },
  {
    name: 'FAQ',
    path: "/faq",
    img: require('../assests/images/sidebar/help.png'),
  },
  {
    name: '1:1 Inquiry(10)',
    path: "/inquiry",
    img: require('../assests/images/sidebar/help.png'),
  },
  {
    name: 'Use Guide',
    path: "/useguide",
    img: require('../assests/images/sidebar/help.png'),
  },
  {
    name: 'Notice',
    path: "/notice",
    img: require('../assests/images/sidebar/help.png'),
  },
])
const logoutfn = ()=>{

  dispatch(loginControl({
    loginStatus: "logout",
}),

  );
  localStorage.setItem('loginstatus', 'logout');
  toast.success("Log out Successfully")
  
}
  useEffect(() => {
    if(document.querySelectorAll(".navbar_sec.active").length>0)
      {           
           var pagelinkofset = document.querySelectorAll(".navbar_sec.active")[0].offsetTop;
           console.log(pagelinkofset,"pagelinkofset");
      document.getElementById("scrollbar_sidebar_id").scrollTo(0,(pagelinkofset - 100));
      }
    },[])
  return (
    <>
    <div className={`d-flex d-xl-none ${cascading.menubar}`}>
    <MdMenu onClick={() => setMbmenu(true)} />
     <div>
     <Link to="/"><img src={logo} alt='logo' className={`img-fluid ${cascading.logoss}`} /></Link>
     </div>
   </div>
    <div className={`${cascading.sidebar}`} >
    
      <div className={windowWidth > 1199 ? `${cascading.sidebarsec}` : mbmenu ? `${cascading.sidebarsec} ${cascading.mobilemenu} ${cascading.mobilemenuopen}` : `${cascading.sidebarsec} ${cascading.mobilemenu}`}>

        <div className={`d-block d-xl-none ${cascading.closeicons}`}>
          <IoClose onClick={() => setMbmenu(false)} />
        </div>
        <div className={`${cascading.siderow}`} id='scrollbar_sidebar_id'>
          <div className={`${cascading.topsec}`}>

         
          <div className={`${cascading.logosec}`}>
            <Link to="/"><img src={logo} alt='logo' className={`img-fluid ${cascading.logoimg}`} /></Link>
          </div>
          <img src={goldline} className={`img-fluid ${cascading.goldline}`} alt='goldline' />
          </div>
          <div className={`${cascading.com_btnsec}`}>
            <NavLink to="/community" className={location.pathname == "/community" ? `btn ${cascading.themebtn1}` : `btn ${cascading.themebtn}`}>Community</NavLink>

          </div>
          <ul className={`${cascading.sidelist}`}>
            {sidemenu.map((e) => (
              <li className={location.pathname == e.path ? `${cascading.activemenus}` : ''}>
                 <NavLink  to={e.path} className={`navbar_sec`}>
                  <div className={`${cascading.iconrow}`}>
                    <img src={e.img} alt='icon' className={`img-fluid ${cascading.icon}`} />
                    <p className={`${cascading.headname}`}>{e.name}</p>
                  </div></NavLink></li>
            ))}
             <li onClick={()=>logoutfn()} >
                 <NavLink  to={"/"} >
                  <div className={`${cascading.iconrow}`}>
                    <img src={require('../assests/images/sidebar/help.png')} alt='icon' className={`img-fluid ${cascading.icon}`} />
                    <p className={`${cascading.headname}`}>Logout</p>
                  </div></NavLink></li>
           
          </ul>
        </div>

      </div>
    </div>
    </>
  )
}

export default Sidebar