import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useDemoData } from "@mui/x-data-grid-generator";
import Select from 'react-select';

import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Pricecard from '../components/pricecard';
import Tableview from '../components/tableview';
import { CiSearch } from 'react-icons/ci';
import DateRangePicker from '../components/datepicker';
import { FaCopy } from "react-icons/fa";
import { MdOutlineQrCodeScanner } from 'react-icons/md';
import Withdrawmodal from '../components/withdrawmodal';
import Innerfooter from '../common/innerfooter';
import StyledDataGrid from '../common/StyleDataGrid';
const options = [
  { value: 'USDT', label: 'USDT(TRC20)' },
  { value: 'BTC', label: 'BTC' },
];
const chargersoption = [
  { value: '3 months', label: '3 Months(3,000 USDT)' },
  { value: '6 months', label: '6 Months(6,000 USDT)' },
  { value: '12 months', label: '12 Months(10,000 USDT)' },
  { value: '24 months', label: '24 Months(20,000 USDT)' },

];
function TransactionDetails() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [paymenttab, setPaymenttab] = useState(true);
  const [withdrawtab, setWithdrawtab] = useState(true);
  const [withdrawmodal, setWithdrawmodal] = useState(false);
  const [selectedtoken, setSelectedtoken] = useState(options[0]);
  const [selectchargers, setSelectchargers] = useState( { value: '0', label: 'Share Product Charging' },);
  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };
  const columns = [
    { field: 'sno', headerName: '#', minWidth: 100,flex:1 },
    { field: 'number', headerName: 'Number', minWidth: 100,flex:1  },
    { field: 'date', headerName: 'Date', type: 'Date', minWidth: 150,flex:1  },
    { field: 'tokentype', headerName: 'Token Type', minWidth: 100,flex:1  },
    { field: 'appliedquantity', headerName: 'Applied quantity', minWidth: 150,flex:1  },
    { field: 'type', headerName: 'Type', minWidth: 150,flex:1  },
    { field: 'sentaddress', headerName: 'Sent Address', minWidth: 150,flex:1  },
    { field: 'receivedaddress', headerName: 'Received Address', minWidth: 150,flex:1  },
    { field: 'txid', headerName: 'Txid', minWidth: 100,flex:1  },
    { field: 'status', headerName: 'Status', minWidth: 150,flex:1 , renderCell: (params) => <div className={params.value == "Completed" ? `${cascading.statuslabel} ${cascading.completed}` : params.value == "Hold" ? `${cascading.statuslabel} ${cascading.hold}` : `${cascading.statuslabel} ${cascading.cancel}`}>{params.value}</div>, },


  ];

  const rows = [
    { id: 0, sno: 1, number: '10000', date: '2023-11-09 23:11:26', tokentype: 'USDT', appliedquantity: '1,000,000,000', type: 'TRD Charging', sentaddress: 'Tx123asdfsdas…', receivedaddress: 'TxDS13F6DFsdf…', txid: 'Txdfsfff', status: "Completed" },
    { id: 1, sno: 2, number: '10000', date: '2023-11-09 23:11:26', tokentype: 'USDT', appliedquantity: '1,000,000,000', type: 'TRD Charging', sentaddress: 'Tx123asdfsdas…', receivedaddress: 'TxDS13F6DFsdf…', txid: 'Txdfsfff', status: "Hold" },
    { id: 2, sno: 3, number: '10000', date: '2023-11-09 23:11:26', tokentype: 'USDT', appliedquantity: '1,000,000,000', type: 'TRD Charging', sentaddress: 'Tx123asdfsdas…', receivedaddress: 'TxDS13F6DFsdf…', txid: 'Txdfsfff', status: "Cancel" },

  ];
  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 1000,
    maxColumns: 4
  });
  const inputElement = useRef();
 


  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (

      <Pagination
        color="standard"
        shape="rounded"
        page={page + 1}
        // className={`${styles.pagecustom}`}
        count={pageCount}
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />

    );
  }



  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>

              <Pricecard />
              <div className={`${cascading.innercontent} ${cascading.mpayment} `}>
                <p className={`${cascading.pagehead} mb-4`}>M-DEPOSIT & WITHDRAW</p>
                <div className={`${cascading.paymenttabsec}`}>
                  <div className={`${cascading.paymenttab} mb-4`}>
                    <div className={paymenttab ? `${cascading.tabstyle} ${cascading.activetabstyle}` : `${cascading.tabstyle}`} onClick={() => setPaymenttab(true)}>
                      <p>DEPOSIT</p>
                    </div>
                    <div className={!paymenttab ? `${cascading.tabstyle} ${cascading.activetabstyle}` : `${cascading.tabstyle}`} onClick={() => setPaymenttab(false)}>
                      <p>WITHDRAW</p>
                    </div>
                  </div>
                  {paymenttab ?
                    <div className={`${cascading.paymentfieldsec}`}>
                      <div className={`${cascading.inputrow} mb-4`}>
                        <p className={`${cascading.inputlabel}`}>Balance</p>
                        <div className={`input-group`}>
                          <input type="text" className={`form-control`} placeholder="Balance" value={"1,00000000USDT"} />
                          <span className={`input-group-text`}>1,000,000 KRW</span>
                        </div>

                      </div>
                      <div className={`${cascading.inputrow} mb-4`}>
                        <p className={`${cascading.inputlabel}`}>Token Type</p>
                        <div className={`${cascading.selectstyle}`}>
                          <Select
                            defaultValue={selectedtoken}
                            onChange={setSelectedtoken}
                            options={options}
                            classNamePrefix={"reactselect"}
                            className='reactselect_container'

                          />
                        </div>

                      </div>
                      <div className={`${cascading.inputrow} mb-4`}>
                        <p className={`${cascading.inputlabel}`}>Address</p>
                        <div className={`input-group`}>
                          <input type="text" className={`form-control`} placeholder="Balance" value={"Txsdsdsdad23k23sdfsdfs24kdl23l3kasddsdfssdl"} />
                          <span className={`input-group-text`}><FaCopy className='me-2' /> <MdOutlineQrCodeScanner /></span>

                        </div>

                      </div>
                      <div className={`${cascading.inputrow} mb-4`}>
                        <p className={`${cascading.inputlabel}`}></p>
                        <div className={``}>
                          <p className={`${cascading.notes}`}> Minimum deposit amount: 100 USDT. Any deposits less than the minimum will not be credited or refunded.
                            - TRC20-USDT only support direct transfer instead of transactions generated from smart contract</p>

                        </div>

                      </div>
                      {/* <div className={`${cascading.btnsec}`}>
                        <button className={`${cascading.themebtn} btn`}>Deposit</button>

                      </div> */}


                    </div>
                    :
                    <div className={`${cascading.paymentfieldsec}`}>
                      <div className={`${cascading.withdrawtabsec} mb-4`}>
                        <div className={`${cascading.tabbtn}`} onClick={() => setWithdrawtab(true)}>
                          <span className={`${cascading.radiobtn}`}>
                            {withdrawtab && <div className={`${cascading.activebtn}`}></div>}
                          </span>   <p>Withdraw of External Address</p>
                        </div>

                        <div className={`${cascading.tabbtn}`} onClick={() => setWithdrawtab(false)}>
                          <span className={`${cascading.radiobtn}`}>
                            {!withdrawtab && <div className={`${cascading.activebtn}`}></div>}
                          </span>
                          <p>Charging & product payment</p>
                        </div>

                      </div>

                      <div className={`${cascading.inputrow} mb-4`}>
                        <p className={`${cascading.inputlabel}`}>Balance</p>
                        <div className={`input-group`}>
                          <input type="text" className={`form-control`} placeholder="Balance" value={"1,00000000USDT"} />
                          <span className={`input-group-text`}>USDT</span>
                        </div>

                      </div>
                      {withdrawtab ?
                        <div className={`${cascading.inputrow} mb-4`}>
                          <p className={`${cascading.inputlabel}`}>Token Type</p>
                          <div className={`${cascading.selectstyle}`}>
                            <Select
                              defaultValue={selectedtoken}
                              onChange={setSelectedtoken}
                              options={options}
                              classNamePrefix={"reactselect"}
                              className='reactselect_container'

                            />
                          </div>

                        </div>
                        :
                        <div className={`${cascading.inputrow} mb-4`}>
                          <p className={`${cascading.inputlabel}`}></p>
                          <div className={`${cascading.selectstyle}`}>
                            <Select
                              className='reactselect_container'
                              defaultValue={selectchargers}
                              onChange={setSelectchargers}
                              options={chargersoption}
                              classNamePrefix={"reactselect"}

                            />
                          </div>

                        </div>
                      }
                      <div className={`${cascading.inputrow} mb-4`}>
                        <p className={`${cascading.inputlabel}`}>Withdrawal Address</p>
                        <div className={`${cascading.ordinp}`}>
                          <input type="text" className={`form-control`} placeholder="Address" value={"Txsdsdsdad23k23sdfsdfs24kdl23l3kasddsdfssdl"} />

                        </div>

                      </div>
                      <div className={`${cascading.inputrow} mb-4`}>
                        <p className={`${cascading.inputlabel}`}>Withdrawal Quantity</p>
                        <div className={`${cascading.ordinp}`}>
                          <input type="text" className={`form-control`} placeholder="Quantity" value={"100,000,000"} />

                        </div>

                      </div>
                      <div className={`${cascading.inputrow} mb-4`}>
                        <p className={`${cascading.inputlabel}`}></p>
                        <div className={``}>
                          <p className={`${cascading.notes}`}>  Minimum deposit amount: 100 USDT. Any deposits less than the minimum will not be credited or refunded.
                            - TRC20-USDT only support direct transfer instead of transactions generated from smart contract</p>

                        </div>

                      </div>
                      <div className={`${cascading.btnsec}`}>
                        <button className={`${cascading.themebtn} btn`} onClick={() => setWithdrawmodal(true)}>Withdrawal</button>

                      </div>


                    </div>
                  }


                </div>
                <div className={`${cascading.tablesec} mt-4`}>
                  <div className={`${cascading.filtersec}`}>
                    <div className={`${cascading.inputinside}`}>
                      <input
                        type="text"
                        placeholder="Search..."
                      // value={searchTerm}
                      // onChange={handleSearchChange}
                      />
                      <CiSearch />
                    </div>
                    <DateRangePicker startDate={startDate} endDate={endDate} onChange={handleDateChange} />


                  </div>
                  <div id="styletable">
                    <DataGrid
                      ref={inputElement}
                      {...data}
                      disableColumnMenu
                      rows={rows}
                      sx={{
                        ...StyledDataGrid(),
                      }}
                      columns={columns}

                      components={{
                        Pagination: CustomPagination,
                      }}
                    // slots={{
                    //   footer: CustomPagination,
                    // }}



                    />
                  </div>

                </div>
              </div>
              <Innerfooter />


            </div>

          </Grid>
        </Grid>
      </div>
      {withdrawmodal && <Withdrawmodal show={withdrawmodal} onClose={() => setWithdrawmodal(false)} />}
    </>
  )
}

export default TransactionDetails