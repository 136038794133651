import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useDemoData } from "@mui/x-data-grid-generator";

import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Pricecard from '../components/pricecard';
import Tableview from '../components/tableview';
import { CiSearch } from 'react-icons/ci';
import DateRangePicker from '../components/datepicker';
import Innerfooter from '../common/innerfooter';
import StyledDataGrid from '../common/StyleDataGrid';
function TransactionDetails() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tradetype,setTradetype] = useState("future");
  const [sitetype,setSitetype] = useState("bibit")

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };
  const columns = [
    { field: 'sno', headerName: '#', width: 100 },
    { field: 'number', headerName: 'Number', width: 150 },
    { field: 'date', headerName: 'Order time', type: 'Date', width: 150 },
    { field: 'coin', headerName: 'Coin/Futures', width: 150, },
    { field: 'trip', headerName: 'Order Tip', width: 100, },
    { field: 'price', headerName: 'Order Price ㅣ Average Price', width: 200, },
    { field: 'quantity', headerName: 'Order Quantity ㅣ Order Volume', width: 200, },
    { field: 'pnL', headerName: 'pnL', width: 150, },
 


  ];

  const rows = [
    {  id: 0, sno: 1,number:"100000", date: '2023-11-09 23:11:26', coin: 'USDT l BTC / USDT', trip: 'Market', trd: '900,000', price: '31,712.1 ㅣ 31,712.1', quantity: ' 0.692      ㅣ 0.692', pnL: '+898.92' },
    {  id: 0, sno: 1,number:"100000", date: '2023-11-09 23:11:26', coin: 'USDT l BTC / USDT', trip: 'Market', trd: '900,000', price: '31,712.1 ㅣ 31,712.1', quantity: ' 0.692      ㅣ 0.692', pnL: '+898.92' },
    {  id: 0, sno: 1,number:"100000", date: '2023-11-09 23:11:26', coin: 'USDT l BTC / USDT', trip: 'Market', trd: '900,000', price: '31,712.1 ㅣ 31,712.1', quantity: ' 0.692      ㅣ 0.692', pnL: '+898.92' },
  
   

  ];
  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 1000,
    maxColumns: 4
  });
  const inputElement = useRef();



  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (

      <Pagination
        color="standard"
        shape="rounded"
        page={page + 1}
        // className={`${styles.pagecustom}`}
        count={pageCount}
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />

    );
  }



  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>

              <Pricecard />
              <div className={`${cascading.innercontent} ${cascading.transcationdetails} `}>
                <p className={`${cascading.pagehead} mb-4`}>Transaction Details</p>
                <div className={`${cascading.tabsec}`}>
                  <div className={`${cascading.tradetab}`}>
                    <button className={tradetype == "future" ? `${cascading.tabbtn} ${cascading.tabbtnactive} btn` :`${cascading.tabbtn} btn`} onClick={()=>setTradetype("future")}>Future trading</button>
                    <button className={tradetype == "spot" ? `${cascading.tabbtn} ${cascading.tabbtnactive} btn` : `${cascading.tabbtn} btn`} onClick={()=>setTradetype("spot")}>Spot Trading</button>
                  </div>
                  <div className={`${cascading.sitetab}`}>
                    <button className={sitetype == "bibit" ? `${cascading.tabbtn} ${cascading.tabbtnactive} btn` : `${cascading.tabbtn} btn`}  onClick={()=>setSitetype("future")}>Bibit</button>
                    <button className={sitetype == "lbank" ? `${cascading.tabbtn} ${cascading.tabbtnactive} btn` : `${cascading.tabbtn} btn`} onClick={()=>setSitetype("lbank")}>L-Bank</button>
                    <button className={sitetype == "bitget" ? `${cascading.tabbtn} ${cascading.tabbtnactive} btn` : `${cascading.tabbtn} btn`} onClick={()=>setSitetype("bitget")}>Bitget</button>
                    <button className={sitetype == "binance" ? `${cascading.tabbtn} ${cascading.tabbtnactive} btn` : `${cascading.tabbtn} btn`} onClick={()=>setSitetype("binance")}>Binance</button>
                  </div>

                </div>
                <div className={`${cascading.tablesec} mt-4`}>
                <div className={`${cascading.filtersec}`}>
                <div className={`${cascading.inputinside}`}> 
                <input
                    type="text"
                    placeholder="Search..."
                    // value={searchTerm}
                    // onChange={handleSearchChange}
                />
                   <CiSearch />
                </div>
                <DateRangePicker startDate={startDate} endDate={endDate} onChange={handleDateChange} />

     
                </div>
                  <div id="styletable">
                    <DataGrid
                      ref={inputElement}
                      {...data}
                      disableColumnMenu
                      rows={rows}
                      sx={{
                        ...StyledDataGrid(),
                      }}
                      columns={columns}

                      components={{
                        Pagination: CustomPagination,
                      }}
                    // slots={{
                    //   footer: CustomPagination,
                    // }}



                    />
                  </div>

                </div>
              </div>
              <Innerfooter/>


            </div>

          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default TransactionDetails