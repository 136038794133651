import React, { useState } from 'react'
import Grid from '@mui/material/Grid';
import Countdown from 'react-countdown';
import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';

import Innerheader from '../common/innerheader';
import Pricecard from '../components/pricecard'
import Userlist from '../components/userlist';
import Createpost from '../components/createpost';
import Postsection from '../components/postsection';
import serverimg from '../assests/images/server.svg';
import startimg from '../assests/images/serverstart.svg';
import stopimg from '../assests/images/serverstop.svg'
import Timercount from '../components/Timercount';
import ProgressBar from '../components/Progressbar';
import Innerfooter from '../common/innerfooter';
const Completionist = () => <span>You are good to go!</span>;
function Servertimer() {

  const [serverstatus, setServerstatus] = useState([
    {
      img: startimg,
      name: "SERVER START",
      label: "Start of monthly trading server",
      value: "2023.12.31",
    },
    {
      img: stopimg,
      name: "SERVER STOP",
      label: "Terminate monthly trading server",
      value: "2023.12.31",
    },

  ])
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return <Timercount days={days} hours={hours} minutes={minutes} seconds={seconds} completed={completed} />


      // <span>{days}:{hours}:{minutes}:{seconds}</span>;
    }
  };
  return (
    <>
      {/* <Innerheader/> */}
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>
              <Pricecard />
              <div className={`${cascading.serverpage} ${cascading.innercontent}`}>
              <p className={`${cascading.pagehead}`}>SERVER TIMER</p>
                <div className='row mt-4'>
                  <div className={`col-12 mb-4`}>
                    <div className={`${cascading.informationcard} ${cascading.gaugecard}`}>
                      <div className={`row ${cascading.informrow}`}>
                        <div className={`col-sm-4 ${cascading.leftside}`}>
                          <div className={`${cascading.card}`}>
                            <img src={serverimg} className={`img-fluid`} alt='cardicn' />
                            <p className={`${cascading.name}`}>SERVER POWER GAUGE</p>
                          </div>

                        </div>
                        <div className={`col-sm-8 ${cascading.rightside} ps-sm-0 `}>
                          <div className={`${cascading.toprow}`}>
                            <div className={`${cascading.toplabelrow}`}>
                              <div className={``}>
                                <p className={`${cascading.label}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                              </div>
                              <div className={`${cascading.borderline}`}></div>
                              <div className={``}>
                                <p className={`${cascading.label}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                              </div>

                            </div>


                          </div>
                          <div className={`${cascading.btmrow}`}>
                              <span className={`${cascading.redcircle}`}> </span>
                              <ProgressBar progress={80}/>
                              <span className={`${cascading.greencircle}`}> </span>
                          </div>

                        </div>

                      </div>

                    </div>

                  </div>
                </div>
                <div className={`row`}>
                  {serverstatus.map((e) => (
                    <div className={`col-lg-6 mb-4`}>
                      <div className={`${cascading.informationcard}`}>
                        <div className={`row ${cascading.informrow}`}>
                          <div className={`col-sm-4 ${cascading.leftside}`}>
                            <div className={`${cascading.card}`}>
                              <img src={e.img} className={`img-fluid`} alt='cardicn' />
                              <p className={`${cascading.name}`}>{e.name}</p>
                            </div>

                          </div>
                          <div className={`col-sm-8 ${cascading.rightside} ps-sm-0 `}>
                            <div className={`${cascading.toprow}`}>
                              <p className={`${cascading.label}`}>{e.label}</p>
                            </div>
                            <div className={`${cascading.btmrow}`}>
                              <p className={`${cascading.value}`}>{e.value}</p>
                            </div>

                          </div>

                        </div>

                      </div>
                    </div>
                  ))}


                </div>
                <div>
                  <Countdown
                    date={Date.now() + 5000000000}
                    renderer={renderer}
                  />
                </div>

              </div>
              <Innerfooter/>
            </div>

          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Servertimer