import React, { useState } from 'react';
import cascading from '../assests/scss/all.module.scss'
import Dropdown from 'react-bootstrap/Dropdown';
import pfimg from '../assests/images/dummypf.png';
import { BsThreeDotsVertical } from "react-icons/bs";
function Postsection(props) {

    return (
        <>
        {console.log(props.datas,"asdasd")}
             {props.datas.map((e) => (
            <div className={`${cascading.postsection} mt-4`}>
                <div className={`${cascading.topsec} px-4 py-3`}>
                    <div className={`${cascading.pfsec} `}>
                        <img
                            // src={props.img}
                            src={pfimg}
                            className={`${cascading.pfimg} img-fluid`} alt='' />
                        <p className={`${cascading.name}`}>People</p>
                    </div>
                    <div>
                        <Dropdown className={`${cascading.dropstyle}`}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <BsThreeDotsVertical />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>


                </div>
                <div className={`${cascading.contentsec}`}>
                    <p className={`${cascading.para}`}>{e.para}</p>
                    <div className={`${cascading.imgsec}`}>
                        {e.imgs.map((e) => (
                            <div>
                                <img src={e.img} className={`${cascading.postimg} img-fluid`} alt='postimg' />
                            </div>
                        ))}


                    </div>


                </div>


            </div>
             ))}
        </>


    )
}

export default Postsection