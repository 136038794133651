import React from 'react'
import cascading from '../assests/scss/all.module.scss'
export default function Plancard(props) {
    return (
        <div>
            <div className={`${cascading.plancard}`}>
                <div className={`${cascading.countimg}`}>
                       <img src={props.datas.img} className={`img-fluid`} alt='plan'/>
                </div>
                <div className={`${cascading.centerdiv}`}>
               
                <div className={`${cascading.toprow}`}>
                    <p className={`${cascading.head}`}>{props.datas.head}</p>
                    <p className={`${cascading.subhead}`}>{props.datas.subhead}</p>
                </div>
                <div className={`${cascading.btmrow}`}>
                    <p className={`${cascading.price}`}>{props.datas.price}</p>
                    <p className={`${cascading.desc}`}>{props.datas.desc}</p>
                </div>
                </div>
                <div className={`${cascading.btnsec}`}>
                    <button className={ props.datas.status == "active" ? `${cascading.selectbtn}`:`${cascading.deselectbtn}`}>Select</button>

                </div>

            </div>
        </div>
    )
}

