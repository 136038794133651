import React, { useEffect, useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useDemoData } from "@mui/x-data-grid-generator";

import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Pricecard from '../components/pricecard';
import Tableview from '../components/tableview';
import { CiSearch } from 'react-icons/ci';
import DateRangePicker from '../components/datepicker';
import Innerfooter from '../common/innerfooter';
import centersaw from '../assests/images/centerland.png';
import ladderimg from '../assests/images/ladder2.png';
import ladderline from '../assests/images/ladderline.png';
import leftsaw from '../assests/images/leftsaw.png';
import rightsaw from '../assests/images/rightsaw.png';
import StyledDataGrid from '../common/StyleDataGrid';
function Gasbalance() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [ladder,setLadder]= useState('center')
  const[chargingval,setChargingval] = useState()
  const[exchangeval,setExchangeval] = useState()
  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };
  const columns = [
    { field: 'sno', headerName: '#', minWidth: 100,flex:1 },
    { field: 'date', headerName: 'Date', type: 'Date', minWidth: 150 ,flex:1},
    { field: 'pnl', headerName: 'PnL %', minWidth: 100,flex:1 },
    { field: 'pnlquantity', headerName: 'PnL Quantity', minWidth: 150,flex:1 },
    { field: 'trd', headerName: 'TRD Balance', minWidth: 150,flex:1},
    { field: 'powerloss', headerName: 'Power Loss', minWidth: 150,flex:1 },
    { field: 'details', headerName: 'Details', minWidth: 150, flex:1},
    { field: 'powerstatus', headerName: 'Power Status', width: 100,flex:1 },
 


  ];

  const rows = [
    {  id: 0, sno: 1, date: '2023-11-09 23:11:26', pnl: '25%', pnlquantity: '1,000,000', trd: '900,000', powerloss: '0', details: '-100,000', powerstatus: 'ON' },
    {  id: 1, sno: 2, date: '2023-11-09 23:11:26', pnl: '25%', pnlquantity: '1,000,000', trd: '900,000', powerloss: '0', details: '-100,000', powerstatus: 'ON' },
    {  id: 2, sno: 3, date: '2023-11-09 23:11:26', pnl: '25%', pnlquantity: '1,000,000', trd: '900,000', powerloss: '0', details: '-100,000', powerstatus: 'ON' },
    {  id: 3, sno: 4, date: '2023-11-09 23:11:26', pnl: '25%', pnlquantity: '1,000,000', trd: '900,000', powerloss: '0', details: '-100,000', powerstatus: 'ON' },
    {  id: 4, sno: 5, date: '2023-11-09 23:11:26', pnl: '25%', pnlquantity: '1,000,000', trd: '900,000', powerloss: '0', details: '-100,000', powerstatus: 'ON' },
    {  id: 5, sno: 6, date: '2023-11-09 23:11:26', pnl: '25%', pnlquantity: '1,000,000', trd: '900,000', powerloss: '0', details: '-100,000', powerstatus: 'ON' },
   

  ];
  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 1000,
    maxColumns: 4
  });
  const inputElement = useRef();
 


  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (

      <Pagination
        color="standard"
        shape="rounded"
        page={page + 1}
        // className={`${styles.pagecustom}`}
        count={pageCount}
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />

    );
  }

useEffect(()=>{
    dummy()

},[])

const dummy =async()=>{
  const chargingbal = [200,300,100,400,70,50,200,300,100,400,70,50,200,300,100,400,70,50,200,300,100,400,70,50,200,300,100,400,70,50,200,300,100,400,70,50]
  const exchangebal = [300,100,100,500,90,20,300,100,100,500,90,20,300,100,100,500,90,20,300,100,100,500,90,20,300,100,100,500,90,20,300,100,100,500,90,20]
  let i =0;
  do{
    if(chargingbal[i] > exchangebal[i]){
      setLadder('left');
     
    }
    else if (chargingbal[i] < exchangebal[i])  {
      setLadder('right');
       console.log("sec");
    }
    else  {
      setLadder('center');
    }
    setChargingval(chargingbal[i]);
    setExchangeval(exchangebal[i]);
   await Timeout()
    console.log("I-->",i)
    i = chargingbal.length -1 == i ? 0 : i+1
  }while(true)
}

const Timeout =()=>{
  return new Promise(resolve => setTimeout(() => {
    resolve()
  }, 1000))
}

  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>

              <Pricecard />
              <div className={`${cascading.innercontent} ${cascading.gasbalancepage}`}>
                <p className={`${cascading.pagehead}`}>Trading Gas Balance</p>
                <div className={`${cascading.seesawsec}`}>
                  <img src={centersaw} className={`img-fluid ${cascading.centerimg}`} alt='saw' />
                 
                   <div className={` ${cascading.laddersec} ` }>
                    <div className={`${cascading.ladderinner}`}>
                    <div className={ladder =='left' ? `${cascading.leftslide} ${cascading.ladderline} ` : ladder =='right' ? `${cascading.rightslide} ${cascading.ladderline} `:`${cascading.ladderline}`  }>
                    <img src={ladderline} className={`img-fluid ${cascading.ladderimg}`} alt='ladderimg' />
                    </div>
                    <div className={ladder =='left' ? `${cascading.leftslide} ${cascading.leftvaluesec} ` : ladder =='right' ? `${cascading.rightslide} ${cascading.leftvaluesec} `:`${cascading.leftvaluesec}`}>
                    <p className={ladder =='left' ? `${cascading.greenlabel}`:ladder =='right' ? `${cascading.redlabel}` : ""}>{chargingval} USDT</p>
                    <img src={leftsaw} className={`img-fluid ${cascading.leftsaw}`} alt='leftsaw' />
                     
                    </div>
                    <div className={ladder =='right' ? `${cascading.leftslide} ${cascading.rightvaluesec} ` : ladder =='left' ? `${cascading.rightslide} ${cascading.rightvaluesec} `:`${cascading.rightvaluesec}`}>
                    <p className={ladder =='right' ? `${cascading.greenlabel}`:ladder =='left' ? `${cascading.redlabel}` : ""}>{exchangeval} USDT</p>
                    <img src={rightsaw} className={`img-fluid ${cascading.rightsaw}`} alt='rightsaw' />
                   
                    </div>
                    </div>
                    </div>
                  
                 

                </div>
                <div className={`${cascading.tablesec} mt-4`}>
                <div className={`${cascading.filtersec}`}>
                <div className={`${cascading.inputinside}`}> 
                <input
                    type="text"
                    placeholder="Search..."
                    // value={searchTerm}
                    // onChange={handleSearchChange}
                />
                   <CiSearch />
                </div>
                <DateRangePicker startDate={startDate} endDate={endDate} onChange={handleDateChange} />

     
                </div>
                  <div id="styletable">
                    <DataGrid
                      ref={inputElement}
                      {...data}
                      disableColumnMenu
                      rows={rows}
                      sx={{
                        ...StyledDataGrid(),
                      }}
                      columns={columns}

                      components={{
                        Pagination: CustomPagination,
                      }}
                    // slots={{
                    //   footer: CustomPagination,
                    // }}



                    />
                  </div>

                </div>
              </div>
              <Innerfooter/>


            </div>

          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Gasbalance