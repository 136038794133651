import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from '../assests/scss/changepwd.module.scss'
function Changemail(props) {
    return (
        <div>
            <Modal show={props.show} onHide={props.onClose} centered className={`${styles.editmodal}`}>
                <Modal.Header closeButton className={`${styles.modalhead}`}>
                    <Modal.Title className={`${styles.modaltitle}`}>Change Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                    <div className={`${styles.inputrow} mb-4`}>
                            <p className={`${styles.inputlabel}`}>Enter Your Current E-Mail</p>
                            <div className={`${styles.ordinp}`}>
                                <input type="text" className={`form-control`} placeholder=""value={""} />
                     
                            </div>

                        </div>
                        <div className={`${styles.inputrow} mb-4`}>
                            <p className={`${styles.inputlabel}`}>Enter E-Mail Address To Change</p>
                            <div className={`${styles.ordinp}`}>
                                <input type="text" className={`form-control`} placeholder=""value={""} />
                     
                            </div>

                        </div>
                      
                        <div className={`${styles.inputrow} mb-4`}>
                            <p className={`${styles.inputlabel}`}>Phone Number Authentications</p>
                            <div className={`${styles.ordinp}`}>
                                <input type="text" className={`form-control`} placeholder=""value={""} />
                     
                            </div>

                        </div>
                        <div className={`${styles.inputrow} mb-4`}>
                            <p className={`${styles.inputlabel}`}>Authentication Input</p>
                            <div className={`${styles.ordinp}`}>
                                <input type="text" className={`form-control`} placeholder=""value={""} />
                     
                            </div>

                        </div>
                    
                     
                        
                      
                        
                    </div>



                </Modal.Body>
                <Modal.Footer className={`${styles.modalfooter}`}>
                 <button className={`${styles.themebtn} btn`} onClick={props.onClose}>Confirm</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Changemail