import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from '../assests/scss/spotmodal.module.scss'
function Spotmodal(props) {
    return (
        <div>
            <Modal show={props.show} onHide={props.onClose} centered className={`${styles.spotmodal}`}>
                <Modal.Header closeButton className={`${styles.modalhead}`}>
                    <Modal.Title className={`${styles.modaltitle}`}>Spot Trading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                 
                       <p className={`${styles.desc}`}>SPOT Trading is not currently a supported service.</p>
                     
                        
                      
                        
                    </div>



                </Modal.Body>
                <Modal.Footer className={`${styles.modalfooter}`}>
                 <button className={`${styles.themebtn} btn`} onClick={props.onClose}>Ok</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Spotmodal