import React from 'react';
import cascading from '../assests/scss/all.module.scss'// Import CSS for styling

const ProgressBar = ({ progress }) => {
  // Determine color based on progress value
  let color;
  if (progress < 33.33) {
    color = 'red'; // Color for the first range (0% - 33.33%)
  } else if (progress < 66.66) {
    color = 'yellow'; // Color for the second range (33.33% - 66.66%)
  } else {
    color = '#A5FA96'; // Color for the third range (66.66% - 100%)
  }

  // Inline style for the progress bar
  const progressBarStyle = {
    width: `${progress}%`,
    background: color,
  };

  return (
    <div className={cascading.progresscontainer}>
      <div className={cascading.progressbar} style={progressBarStyle}></div>
    </div>
  );
};

export default ProgressBar;