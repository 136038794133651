import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useDemoData } from "@mui/x-data-grid-generator";

import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Pricecard from '../components/pricecard';
import Tableview from '../components/tableview';
import { CiSearch } from 'react-icons/ci';
import DateRangePicker from '../components/datepicker';
import Innerfooter from '../common/innerfooter';
import StyledDataGrid from '../common/StyleDataGrid';
function EXpayment() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const[activesite,setActivesite]=useState("bibit")

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };
  const columns = [
    { field: 'sno', headerName: '#', minWidth: 100,flex:1 },
    { field: 'uid', headerName: 'UID', minWidth: 150,flex:1 },
    { field: 'code', headerName: 'Code', minWidth: 150,flex:1 },
    { field: 'type', headerName: 'Type', minWidth: 100,flex:1 },
    { field: 'chaintype', headerName: 'Chain type', minWidth: 150, flex:1},
    { field: 'quantity', headerName: 'Quantity', minWidth: 150,flex:1 },
    { field: 'date', headerName: 'Time', type: 'Date', minWidth: 200,flex:1 },
 


  ];

  const rows = [
    {  id: 0, sno: 1, uid: 'MSC-326', code: '545845452', type: 'Deposit', chaintype: 'blockchain', quantity: '3,000,000', date: '2023-10-31  15:54:31' },
    {  id: 1, sno: 2, uid: 'MSC-326', code: '545845452', type: 'Withdraw', chaintype: 'blockchain', quantity: '3,000,000', date: '2023-10-31  15:54:31' },
    {  id: 0, sno: 3, uid: 'MSC-326', code: '545845452', type: 'Deposit', chaintype: 'blockchain', quantity: '3,000,000', date: '2023-10-31  15:54:31' },
    

  
   

  ];
  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 1000,
    maxColumns: 4
  });
  const inputElement = useRef();
 


  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (

      <Pagination
        color="standard"
        shape="rounded"
        page={page + 1}
        // className={`${styles.pagecustom}`}
        count={pageCount}
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />

    );
  }



  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>

              <Pricecard />
              <div className={`${cascading.innercontent} ${cascading.transcationdetails} `}>
                <p className={`${cascading.pagehead} mb-4`}>Exchange Deposit & Withdraw</p>
                <div className={`${cascading.tabsec}`}>
                <div className={`${cascading.tradecount}`}>
                   <div className={`${cascading.countbox}`}>
                    <p>Balance : 3,000,000</p>
                   </div>
                   <div className={`${cascading.countbox}`}>
                    <p>Total Deposit : 3,00,00</p>
                   </div>
                   <div className={`${cascading.countbox}`}>
                    <p>Total Withdraw : 3,00,00</p>
                   </div>
                  </div>
                  <div className={`${cascading.sitetab}`}>
                    <button className={activesite == "bibit" ?`${cascading.tabbtn} ${cascading.tabbtnactive} btn` : `${cascading.tabbtn} btn`} onClick={()=>setActivesite("bibit")}>Bibit</button>
                    <button className={activesite == "lbank" ?`${cascading.tabbtn} ${cascading.tabbtnactive} btn` : `${cascading.tabbtn} btn`} onClick={()=>setActivesite("lbank")}>L-Bank</button>
                    <button className={activesite == "bitget" ?`${cascading.tabbtn} ${cascading.tabbtnactive} btn` : `${cascading.tabbtn} btn`} onClick={()=>setActivesite("bitget")}>Bitget</button>
                    <button className={activesite == "binance" ?`${cascading.tabbtn} ${cascading.tabbtnactive} btn` : `${cascading.tabbtn} btn`}>Binance</button>
                  </div>

                </div>
                <div className={`${cascading.tablesec} mt-4`}>
                <div className={`${cascading.filtersec}`}>
                <div className={`${cascading.inputinside}`}> 
                <input
                    type="text"
                    placeholder="Search..."
                    // value={searchTerm}
                    // onChange={handleSearchChange}
                />
                   <CiSearch />
                </div>
                <DateRangePicker startDate={startDate} endDate={endDate} onChange={handleDateChange} />

     
                </div>
                  <div id="styletable">
                    <DataGrid
                      ref={inputElement}
                      {...data}
                      disableColumnMenu
                      rows={rows}
                      sx={{
                        ...StyledDataGrid(),
                      }}
                      columns={columns}

                      components={{
                        Pagination: CustomPagination,
                      }}
                    // slots={{
                    //   footer: CustomPagination,
                    // }}



                    />
                  </div>

                </div>
              </div>
              <Innerfooter/>

            </div>

          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default EXpayment