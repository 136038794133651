import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useDemoData } from "@mui/x-data-grid-generator";
import Select from 'react-select';

import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Pricecard from '../components/pricecard';
import Tableview from '../components/tableview';
import { CiSearch } from 'react-icons/ci';
import DateRangePicker from '../components/datepicker';
import { FaCopy } from "react-icons/fa";
import { MdOutlineQrCodeScanner } from 'react-icons/md';
import warning from '../assests/images/warn.svg'
import Innerfooter from '../common/innerfooter';
import StyledDataGrid from '../common/StyleDataGrid';
const options = [
  { value: 'USDT', label: 'USDT(TRC20)' },
  { value: 'BTC', label: 'BTC' },
];
function Apisetting() {
  const [paymenttab, setPaymenttab] = useState(true);
  const [selectedtoken, setSelectedtoken] = useState(options[0]);
  const[opt,setOpt]= useState("bitget")
  const [notices, setNotices] = useState([{
    desc: "If the above four pieces of information are not correctly applied, the M-AI trading system will not be activated."

  },
  {
    desc: "The AI system operates only on futures contracts from exchanges connected to the API. If manual trading is          executed, any losses incurred due to system errors are solely the responsibility of the user"

  },
  {
    desc: "If the API connection status does not appear normal, please contact the administrator"

  },
  {
    desc: "Futures leverage x20, and Cross status are normal. It is imperative to verify the settings for Bitcoin, Ethereum,   and Solana markets and never modify these settings under any circumstances. Please exercise extreme caution."

  }])

  const columns = [
    { field: 'date', headerName: 'Date', type: 'Date', minWidth: 150,flex:1 },
    { field: 'time', headerName: 'Time', minWidth: 150,flex:1 },
    { field: 'status', headerName: 'Status', minWidth: 150, flex:1, renderCell: (params) => <div className={params.value == "start" ? `${cascading.statuslabel} ${cascading.completed}` : `${cascading.statuslabel} ${cascading.cancel}`}>{params.value}</div>, },



  ];

  const rows = [
    { id: 0, date: "2023.12. 31", time: '23:59:60', status: 'start' },
    { id: 1, date: '2023.12. 31', time: '23:59:60', status: 'start' },
    { id: 2, date: "2023.12. 31", time: '23:59:60', status: 'stop' },

  ];
  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 1000,
    maxColumns: 4
  });
  const inputElement = useRef();



  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (

      <Pagination
        color="standard"
        shape="rounded"
        page={page + 1}
        // className={`${styles.pagecustom}`}
        count={pageCount}
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />

    );
  }



  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>

              <Pricecard />
              <div className={`${cascading.innercontent} ${cascading.apisettingpage} `}>
                <p className={`${cascading.pagehead} mb-4`}>API Settings</p>
                <div className={`${cascading.paymenttabsec}`}>
                  <div className={`${cascading.paymenttab} mb-4`}>
                    <div className={paymenttab ? `${cascading.tabstyle} ${cascading.activetabstyle}` : `${cascading.tabstyle}`} onClick={() => setPaymenttab(true)}>
                      <p>AI AUTO TRADING API</p>
                    </div>
                    <div className={!paymenttab ? `${cascading.tabstyle} ${cascading.activetabstyle}` : `${cascading.tabstyle}`} onClick={() => setPaymenttab(false)}>
                      <p>INDIGATOR TARGET TRADING API</p>
                    </div>
                  </div>

                </div>
                <div className={`${cascading.midcontent}`}>
                  <div className={`${cascading.tabrow} row`}>
                    <div className={`col-lg-6 mb-4 mb-lg-0`}>
                      <div className={`${cascading.settingopt} py-2 py-lg-0`}>
                        <p>API Setting Video </p>
                        <p>API Setting Manual </p>
                      </div>
                    </div>
                    <div className={`col-lg-6`}>
                      <div>
                        <ul className={`${cascading.alternopt}`}>
                          <li className={opt == 'bitget' && `${cascading.active}`} onClick={()=>setOpt("bitget")}>Bitget Setting</li>
                          {/* <li className={opt == 'binance' && `${cascading.active}`} onClick={()=> setOpt("binance")}>Binance Setting</li>
                          <li className={opt == 'lbank' && `${cascading.active}`} onClick={()=>setOpt("lbank")}>L-Bank Setting</li>
                          <li className={opt == 'bibit' && `${cascading.active}`} onClick={()=>setOpt("bibit")}>Bibit Setting</li>
                          <li className={opt == 'kraken' && `${cascading.active}`} onClick={()=>setOpt("kraken")}>Kraken</li>
                          <li className={opt == 'kucpin' && `${cascading.active}`} onClick={()=>setOpt("kucpin")}>Kucoin</li> */}

                        </ul>
                      </div>

                    </div>

                  </div>
                  <div className={`${cascading.inputsec} mt-5`}>

                    <div className={`row`}>
                      <div className={`col-md-6 mb-4`}>
                      <div className={`${cascading.inputrow} `}>
                        <p className={`${cascading.inputlabel}`}>Enter UID</p>
                        <div className={`input-group`}>
                          <input type="text" className={`form-control`} placeholder="Balance" value={"Txsdsdsdad23k23sdfsdfs24kdl23l3kasddsdfssdl"} />
                          <span className={`input-group-text`}><FaCopy className='me-2' /> </span>

                        </div>

                      </div>
                      </div>
                      <div className={`col-md-6 mb-4`}>
                      <div className={`${cascading.inputrow} `}>
                        <p className={`${cascading.inputlabel}`}>API ID</p>
                        <div className={`input-group`}>
                          <input type="text" className={`form-control`} placeholder="Balance" value={"100,000,00 USDT"} />
                          <span className={`input-group-text`}><FaCopy className='me-2' /> </span>

                        </div>

                      </div>
                      </div>
                      <div className={`col-md-6 mb-4`}>
                      <div className={`${cascading.inputrow} `}>
                        <p className={`${cascading.inputlabel}`}>API Key</p>
                        <div className={`input-group`}>
                          <input type="text" className={`form-control`} placeholder="Balance" value={"1,000,000 USDT"} />
                          <span className={`input-group-text`}><FaCopy className='me-2' /> </span>

                        </div>

                      </div>
                      </div>
                      <div className={`col-md-6 mb-4`}>
                      <div className={`${cascading.inputrow} `}>
                        <p className={`${cascading.inputlabel}`}>Secret key</p>
                        <div className={`input-group`}>
                          <input type="text" className={`form-control`} placeholder="Balance" value={"Txjkj"} />
                          <span className={`input-group-text`}><FaCopy className='me-2' /> </span>

                        </div>

                      </div>
                      </div>
                  
                    </div>
                  </div>
                  <div className={`${cascading.apibtnsec}`}>
                    <button className={`${cascading.applybtn}`}>Apply API</button>
                    <button className={`${cascading.stopbtn}`}>Stop API</button>

                  </div>

                </div>

                <div className={`row`}>
                  <div className={`col-lg-6`}>
                    <div className={`${cascading.noticecard}`}>
                      <div className={`${cascading.toprow}`}>
                        <p>API Importance Notice</p>
                      </div>
                      <div className={`${cascading.btmrow}`}>
                        <img src={warning} className={`img-fluid  ${cascading.warnimg}`} alt='warn' />

                        <ul>
                          {notices.map((e, i) => (
                            <li>{i + 1}. {e.desc}</li>
                          ))}

                        </ul>
                      </div>

                    </div>

                  </div>
                  <div className={`col-lg-6`}>
                    <div className={`${cascading.tablesec} `}>

                      <div id="styletable" className={`${cascading.styletable}`}>
                        <DataGrid
                          ref={inputElement}
                          {...data}
                          disableColumnMenu
                          rows={rows}
                          sx={{
                            ...StyledDataGrid(),
                          }}
                          columns={columns}

                          components={{
                            Pagination: CustomPagination,
                          }}
                        // slots={{
                        //   footer: CustomPagination,
                        // }}



                        />
                      </div>

                    </div>

                  </div>

                </div>

              </div>
              <Innerfooter/>


            </div>

          </Grid>
        </Grid>
      </div>

    </>
  )
}

export default Apisetting