import React from 'react'
import { Link } from 'react-router-dom';
import cascading from '../assests/scss/footer.module.scss';
import logo from '../assests/images/logo.png';
import shadoweffect from '../assests/images/home/shadoweffect.png'
function Footer() {
  return (
    <div className={`${cascading.footerpage}`}>
       <img src={shadoweffect} className={`img-fluid ${cascading.shadowblur}`} alt='shadow'/>
      <div className={`container`}>
        <div className={`row`}>
          <div className={`col-lg-4 ${cascading.leftsec}`}>
            <img src={logo} className={`img-fluid ${cascading.logoimg}`} alt='logo'/>
            <p className={`${cascading.desc}`}>
            Nullam sed diam vitae metus volutpat cursus. Proin hendrerit venenatis mauris ac scelerisque. Nulla eget orci tellus. Quisque aliquet dictum libero at auctor.
            </p>
          </div>
          <div className={`offset-lg-1 col-lg-7 ${cascading.rightsec}`}>
            <div className={`row`}>
              <div className={`col-md-4 col-sm-6`}>
                <p className={`${cascading.listhead}`}>congue malesuada</p>
                <ul className={`${cascading.routelist}`}>
                  <li><Link to="#">massa metus</Link></li>
                  <li><Link to="#">massa metus</Link></li>
                  <li><Link to="#">massa metus</Link></li>
                  <li><Link to="#">massa metus</Link></li>
                  <li><Link to="#">massa metus</Link></li>
                </ul>
              </div>
              <div className={`col-md-4 col-sm-6`}>
                <p className={`${cascading.listhead}`}>congue malesuada</p>
                <ul className={`${cascading.routelist}`}>
                  <li><Link to="#">massa metus</Link></li>
                  <li><Link to="#">massa metus</Link></li>
                  <li><Link to="#">massa metus</Link></li>
                  <li><Link to="#">massa metus</Link></li>
                  <li><Link to="#">massa metus</Link></li>
                </ul>
              </div>
              <div className={`col-md-4 col-sm-6`}>
                <p className={`${cascading.listhead}`}>congue malesuada</p>
                <ul className={`${cascading.routelist}`}>
                  <li><Link to="#">massa metus</Link></li>
                  <li><Link to="#">massa metus</Link></li>
                  <li><Link to="#">massa metus</Link></li>
                  <li><Link to="#">massa metus</Link></li>
                  <li><Link to="#">massa metus</Link></li>
                </ul>
              </div>
              <div className={`col-lg-4`}>

              </div>
              <div className={`col-lg-4`}>

              </div>
            </div>

          </div>

        </div>
        <hr/>
        <p className={`${cascading.copysec}`}>
        Copyright @ 2024 M-Score. All Rights Reserved</p>

      </div>

    </div>
  )
}

export default Footer