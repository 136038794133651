import React from 'react'
import cascading from '../assests/scss/footer.module.scss';
function Innerfooter() {
  return (
    <div className={`${cascading.innerfooter}`}>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever</p>
    </div>
  )
}

export default Innerfooter