import React from 'react'
import Grid from '@mui/material/Grid';
import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Pricecard from '../components/pricecard';
import guideimg from '../assests/images/useguide.png';
import guideimg1 from '../assests/images/useguide1.png';
function Useguide() {


  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>

              <Pricecard />
              <div className={`${cascading.innercontent} ${cascading.useguidepage}`}>
                <p className={`${cascading.pagehead} p-3`}>Use Guide</p>
                <div className={`${cascading.userguidelist} mt-4`}>
                  <p className={`${cascading.head}`}>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'ssince the 1500s, when an unknown printer took a galley of
                  </p>
                  <div className={`row my-4`}>
                    <div className={`col-md-6 mb-4`}>
                      <img src={guideimg} className={`${cascading.imgstyle} img-fluid`} />
                    </div>
                    <div className={`col-md-6 mb-4`}>
                      <img src={guideimg1} className={`${cascading.imgstyle} img-fluid`} />
                    </div>

                  </div>
                  <p className={`${cascading.desc}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry    </p>

                </div>


                <div>

                </div>

              </div>


            </div>

          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Useguide