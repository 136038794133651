import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import ReactApexChart from 'react-apexcharts';
import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Lottie from "lottie-react";
import Innerfooter from '../common/innerfooter';
import Pricecard from '../components/pricecard';
import botjson from '../assests/json/aibot.json'
function Aistatus() {

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: 'area-chart',
      },
      
      colors:['#d0a448', '#FAC749', '#31230f'],
      markers: false,

      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },
      yaxis:{
        opposite: true,
        labels:{
          align: 'right',
        }
      }

    },
    series: [{
      name: 'Series A',
      data: [30, 40, 35, 50, 15, 60, 70, 91, 55, 35, 15, 50]
    }]
  });


  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>

              <Pricecard />
              <div className={`${cascading.innercontent} ${cascading.aistatuspage}`}>
                <p className={`${cascading.pagehead} mb-4`}>AI STATUS</p>
                <div className={`row ${cascading.botairow}`}>
                  <div className={`col-md-6 ${cascading.leftimgsec} mb-4`}>
                    <div className={`${cascading.lottieimgsec}`}>
                      <Lottie animationData={botjson} loop={true} />;
                    </div>
                    <p className={`${cascading.labelstatus}`}>Trading Normally...</p>

                  </div>
                  <div className={`col-md-6 ${cascading.chartsec}`}>
                    <div className="area-chart" id='aichart'>
                      <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={350} />
                    </div>
                  </div>

                </div>

              </div>

              <Innerfooter />
            </div>

          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Aistatus