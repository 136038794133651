import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from '../assests/scss/tradesetting.module.scss'
import Slider from '@mui/material/Slider';
import Select from 'react-select';

const sizes = [
    { value: '25', label: '25% USDT' },
    { value: '38', label: '38% USDT' },
    { value: '55', label: '55% USDT' },
];
const profit = [
    { value: '25', label: '25% USDT' },
    { value: '38', label: '38% USDT' },
    { value: '55', label: '55% USDT' },
];
function Tradesettings(props) {
    const [margin, setMargin] = useState("cross")
    const [selectedSize, setSelectedsize] = useState(sizes[1]);
    const [selectedProfit, setSelectedProfit] = useState({ value: '0', label: 'Mark' },);
    const [sliderValue, setSliderValue] = useState("1");
    const marks = [
        { value: 1, label: '1x' },
        { value: 25, label: '25x' },
        { value: 50, label: '50x' },
        { value: 75, label: '75x' },
        { value: 100, label: '100x' },

    ];

    function valuetext(value) {
        return `${value}x`;
    }

    function valueLabelFormat(value) {
        return value + "x";
        // marks.findIndex((mark) => mark.value === value);
    }
    const handleChange = (event, newValue) => {
        let sliderchn = newValue.toString();
        setSliderValue(sliderchn);
    };

    return (
        <div>
            <Modal show={props.show} onHide={props.onClose} centered className={`${styles.tradesetting} tradesetttings`}>
                <Modal.Header closeButton className={`${styles.modalhead}`}>
                    <Modal.Title className={`${styles.modaltitle}`}>Trade Default Setting</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <div className={`${styles.modalbody}`}>
                        <p className={`${styles.titledesc} mb-3`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <div className={`row`}>
                            <div className={`col-md-6 mb-3`}>
                                <p className={`${styles.labelband}`}>Future Balance</p>
                            </div>
                            <div className={`col-md-6 mb-3`}>
                                <input type="text" className={`form-control`} placeholder="" value={"9,000,000 USDT"} />
                            </div>

                        </div>
                        <div className={`row`}>
                            <div className={`col-md-6 mb-3`}>
                                <div className={`${styles.marginsec} `}>
                                    <div className={`${styles.marginlabel}`}>
                                        <p>Perpetual Margin Mode</p>
                                    </div>
                                    <div className={`row mt-3`}>
                                        <div className={`col-6 mb-3`}>

                                            <div className={margin == "cross" ? `${styles.marginlabelbtn} ${styles.active}` : `${styles.marginlabelbtn}`} onClick={() => setMargin("cross")}>
                                                <p>Cross</p>
                                            </div>

                                        </div>
                                        <div className={`col-6 mb-3`}>
                                            <div className={margin == "isolated" ? `${styles.marginlabelbtn} ${styles.active}` : `${styles.marginlabelbtn}`} onClick={() => setMargin("isolated")}>
                                                <p>Isolated</p>
                                            </div>

                                        </div>

                                    </div>

                                </div>


                            </div>
                            <div className={`col-md-6 mb-3`}>
                                <div className={`${styles.leveragesec}`}>
                                    <div className={`${styles.toprow}`}>
                                        <div className={`${styles.marginlabel} ${styles.active}`} onClick={() => setMargin("cross")}>
                                            <p>Adjust Leverage</p>
                                        </div>
                                        <input type="text" className={`form-control ${styles.levinpt}`} placeholder="" value={sliderValue + "x"} />
                                    </div>
                                    <div className={`${styles.ranger}`}>
                                        <Slider
                                            aria-label="Restricted values"
                                            // value={sliderValue}
                                            sx={{
                                                '& .MuiSlider-thumb': {
                                                    color: "#000000",
                                                    boxShadow: "0px 1px 3px 0px #935EF63D, 1px 1px 2px 0px #FFFFFF3D inset",
                                                    width: "10px",
                                                    height: "10px",
                                                    border: "2px solid #DFC78B"
                                                },
                                                '& .MuiSlider-track': {
                                                    color: "#DFC78B",
                                                    height: '4px'

                                                },
                                                '& .MuiSlider-mark': {
                                                    color: "#DFC78B",
                                                    height: "6px",
                                                    width: "2px",
                                                    borderRadius: "0px",
                                                    boxShadow: "0px 1px 3px 0px #935EF63D ,1px 1px 2px 0px #FFFFFF3D inset;"
                                                },
                                                '& .MuiSlider-markActive': {
                                                    backgroundColor: "#DFC78B",
                                                    height: "6px",
                                                    width: "2px",
                                                    borderRadius: "0px",
                                                    boxShadow: " 0px 1px 3px 0px #935EF63D , 1px 1px 2px 0px #FFFFFF3D inset;"
                                                },
                                                '& .MuiSlider-markLabel': {
                                                    color: "#7E7D82",
                                                    fontSize: "10px"
                                                },
                                                '& .MuiSlider-markLabelActive': {
                                                    color: "#FFFFFF",
                                                    fontSize: "10px"
                                                },


                                                '& .MuiSlider-rail': {
                                                    color: "#7D6B4861",
                                                    height: '6px'
                                                },
                                                '& .MuiSlider-active': {
                                                    color: "green"
                                                }
                                            }}
                                            onChange={handleChange}
                                            valueLabelFormat={valueLabelFormat}
                                            getAriaValueText={valuetext}
                                            valueLabelDisplay="auto"
                                            marks={marks}
                                            step={null}
                                        />
                                    </div>

                                </div>

                            </div>


                        </div>
                        <div className={`row`}>
                            <div className={`col-12 mb-3`}>
                                <p className={`${styles.labelband}`}>Position Entry Quantity</p>
                            </div>
                            <div className={`col-12 mb-3`}>
                                <div className={`${styles.inptside} `}>
                                    <p className={`${styles.leftlabel}`}>Size</p>
                                    <div className={`${styles.reactselect}`}>
                                        <Select
                                            className='reactselect_container tradesettings'
                                            classNamePrefix="reactselect"
                                            defaultValue={selectedSize}
                                            onChange={setSelectedsize}
                                            options={sizes}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={`col-12 mb-3`}>
                                <div className={`${styles.inptside}`}>
                                    <p className={`${styles.leftlabel}`}>Quantity</p>
                                    <div className={`${styles.inputrow} ${styles.qtyinpt}`}>

                                        <div className={`${styles.ordinp}`}>
                                            <input type="text" className={`form-control`} placeholder="Address" value={"100,00,000"} />

                                        </div>
                                         <span>USDT</span>
                                    </div>
                                </div>

                            </div>
                            <div className={`col-12 mb-3`}>
                                <div className={`${styles.ranger}`}>
                                    <Slider
                                        aria-label="Restricted values"
                                        // value={sliderValue}
                                        sx={{
                                            '& .MuiSlider-thumb': {
                                                color: "#000000",
                                                boxShadow: "0px 1px 3px 0px #935EF63D, 1px 1px 2px 0px #FFFFFF3D inset",
                                                width: "10px",
                                                height: "10px",
                                                border: "2px solid #DFC78B"
                                            },
                                            '& .MuiSlider-track': {
                                                color: "#DFC78B",
                                                height: '4px'

                                            },
                                            '& .MuiSlider-mark': {
                                                color: "#DFC78B",
                                                height: "6px",
                                                width: "2px",
                                                borderRadius: "0px",
                                                boxShadow: "0px 1px 3px 0px #935EF63D ,1px 1px 2px 0px #FFFFFF3D inset;"
                                            },
                                            '& .MuiSlider-markActive': {
                                                backgroundColor: "#DFC78B",
                                                height: "6px",
                                                width: "2px",
                                                borderRadius: "0px",
                                                boxShadow: " 0px 1px 3px 0px #935EF63D , 1px 1px 2px 0px #FFFFFF3D inset;"
                                            },
                                            '& .MuiSlider-markLabel': {
                                                color: "#7E7D82",
                                                fontSize: "10px"
                                            },
                                            '& .MuiSlider-markLabelActive': {
                                                color: "#FFFFFF",
                                                fontSize: "10px"
                                            },


                                            '& .MuiSlider-rail': {
                                                color: "#7D6B4861",
                                                height: '6px'
                                            },
                                            '& .MuiSlider-active': {
                                                color: "green"
                                            }
                                        }}
                                        onChange={handleChange}
                                        valueLabelFormat={valueLabelFormat}
                                        getAriaValueText={valuetext}
                                        valueLabelDisplay="auto"
                                        marks={marks}
                                        step={null}
                                    />
                                </div>
                            </div>
                            <div className={`col-12 mb-3`}>
                                <div className={`${styles.losssec}`}>
                                    <p className={`${styles.labelband}`}>TP/SL</p>
                                    <p className={`${styles.labelband}`}>ADVANCED %</p>
                                </div>

                            </div>
                            <div className={`col-12 mb-3`}>
                                <div className={`${styles.inptside} `}>
                                    <p className={`${styles.leftlabel}`}>Take Profit</p>
                                    <div className={`${styles.reactselect}`}>
                                        <Select
                                            className='reactselect_container tradesettings'
                                            classNamePrefix="reactselect"
                                            defaultValue={selectedProfit}
                                            onChange={setSelectedProfit}
                                            options={profit}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`col-12 mb-3`}>
                                <div className={`${styles.inptside} `}>
                                    <p className={`${styles.leftlabel}`}>Stop Loss</p>
                                    <div className={`${styles.reactselect}`}>
                                        <Select
                                            className='reactselect_container tradesettings'
                                            classNamePrefix="reactselect"
                                            defaultValue={selectedProfit}
                                            onChange={setSelectedProfit}
                                            options={profit}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={`col-12 mb-3 ${styles.checkboxsec}`}>
                            <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                 Lorem ipsum dolor sit, amet consectetur adipisicing elit. Inventore, quasi maxime eaque labore alias, excepturi voluptates ea eum mollitia ab debitis in quibusdam ipsum numquam ullam magni quod, accusantium ipsa.
                                </label>
                              </div>
                              </div>

                        </div>





                    </div>



                </Modal.Body>
                <Modal.Footer className={`${styles.modalfooter}`}>
                    <button className={`${styles.applybtn} btn`} onClick={props.onClose}>Apply</button>
                    <button className={`${styles.cancelbtn} btn`} onClick={props.onClose}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Tradesettings