import React from "react";
import { TickerTape, AdvancedChart, TechnicalAnalysis, CompanyProfile } from "react-tradingview-embed";
import cascading from '../assests/scss/all.module.scss'
const Tradechart = () => {
  
    const overrides = {
      "paneProperties.background": "#020024",
        "paneProperties.backgroundType": "solid"
      };
  return (
    <div className={`${cascading.chartstyle}`}>
    {/* Basic Chart */}
    <AdvancedChart
      symbol="BTCUSD"
      interval="1D"
      theme="dark"
      width="100%"
      widgetProps={{"background": "#0FFFFF"}}
      backgroundColor="#fffff" 
      overrides={overrides}
      studies={["MACD@tv-basicstudies", "StochasticRSI@tv-basicstudies", "TripleEMA@tv-basicstudies"]}
    
      
    />
    

  </div>
  );
};

export default Tradechart;