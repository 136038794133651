import React, { useState } from 'react'
import Grid from '@mui/material/Grid';
import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Pricecard from '../components/pricecard';
import { Nav, Tab } from 'react-bootstrap';
import { CiSearch } from 'react-icons/ci';
import Innerfooter from '../common/innerfooter';
function Faq() {
  const [faqmenu, setFaqmenu] = useState([{
    tabname: "How to connect support ?",
    content: "Lorem ipsum dolor sit amet consectetur. Semper amet bibendum ut mus. Semper morbi tellus ipsum vulputate malesuada quam condimentum et. Id fusce dictum sed fringilla venenatis. Vitae rhoncus velit facilisis aliquam amet. Nunc ut pretium egestas sit eget arcu vel nunc cras. Porttitor enim sit sed quis mauris feugiat turpis. Volutpat in ipsum lorem placerat tempus arcu. Sit sed sed vitae ut lectus consequat vitae eu amet. Ultricies pellentesque interdum viverra leo interdum metus amet sed. Neque aliquam dapibus euismod magna. Lobortis sit etiam turpis velit amet. Eget ultrices lectus lacinia in pharetra urna orci. Donec luctus scelerisque amet pharetra erat.Eget commodo interdum rhoncus tempor lacinia nullam id magna vulputate.Mauris at est dolor adipiscing natoque habitasse sagittis.Tellus ultrices nibh nisi a.Facilisis suspendisse nibh porttitor at quis massa congue adipiscing augue.Viverra sem quis tellus neque.Blandit consectetur lacus amet molestie ut aliquam.Lectus quis augue sapien in nisi mattis lectus.Volutpat neque dui amet quisque.Cursus ante facilisis elementum scelerisque."
  },
  {
    tabname: "How to request new features ?",
    content: "Lorem ipsum dolor sit amet consectetur. Semper amet bibendum ut mus. Semper morbi tellus ipsum vulputate malesuada quam condimentum et. Id fusce dictum sed fringilla venenatis. Vitae rhoncus velit facilisis aliquam amet. Nunc ut pretium egestas sit eget arcu vel nunc cras. Porttitor enim sit sed quis mauris feugiat turpis. Volutpat in ipsum lorem placerat tempus arcu. Sit sed sed vitae ut lectus consequat vitae eu amet. Ultricies pellentesque interdum viverra leo interdum metus amet sed. Neque aliquam dapibus euismod magna. Lobortis sit etiam turpis velit amet. Eget ultrices lectus lacinia in pharetra urna orci. Donec luctus scelerisque amet pharetra erat.Eget commodo interdum rhoncus tempor lacinia nullam id magna vulputate.Mauris at est dolor adipiscing natoque habitasse sagittis.Tellus ultrices nibh nisi a.Facilisis suspendisse nibh porttitor at quis massa congue adipiscing augue.Viverra sem quis tellus neque.Blandit consectetur lacus amet molestie ut aliquam.Lectus quis augue sapien in nisi mattis lectus.Volutpat neque dui amet quisque.Cursus ante facilisis elementum scelerisque."
  }])
  const [searchTerm, setSearchTerm] = useState('');


  const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
  };

  const filteredData = faqmenu.filter(item =>
      item.tabname.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>

              <Pricecard />
              <div className={`${cascading.innercontent} ${cascading.faqpage}`}>
                <Tab.Container id="left-tabs-example" defaultActiveKey={faqmenu[0].tabname}>
                  <div className={`row`}>
                    <div className={`col-lg-4 mb-4`}>
                      <div className={`${cascading.faqtabsec}`}>
                        <p className={`${cascading.head}`}>FAQ List</p>
                        <div className={`${cascading.inputsec} mt-4`}>
                          <div className={`${cascading.inputinside}`}>
                            <input
                              type="text"
                              placeholder="Search..."
                              value={searchTerm}
                              onChange={handleSearchChange}
                            />
                            <CiSearch />
                          </div>

                        </div>
                        <div>
                          <Nav variant="pills" className="flex-column pt-4">
                            {filteredData.map((e, i) => (
                              <Nav.Item>
                                <Nav.Link eventKey={e.tabname}>{e.tabname}</Nav.Link>
                              </Nav.Item>
                            ))}

                          </Nav>
                        </div>

                      </div>

                    </div>
                    <div className={`col-lg-8 ${cascading.rightside}`}>
                      <Tab.Content>
                        {faqmenu.map((e, i) => (
                          <Tab.Pane eventKey={e.tabname}>
                            <p className={`${cascading.head}`}>{e.tabname}</p>
                            <p className={`${cascading.content}`}>{e.content}</p>
                          </Tab.Pane>
                        ))}
                      </Tab.Content>

                    </div>

                  </div>
                </Tab.Container>


                <div>

                </div>

              </div>
              <Innerfooter/>

            </div>

          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Faq