import React, { useRef, useState } from 'react'
import Switch from 'react-switch';
import { FaCopy } from "react-icons/fa";
import Grid from '@mui/material/Grid';
import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Pricecard from '../components/pricecard';
import dummypf from '../assests/images/dummypf.png';
import vipimg from '../assests/images/vvip.png';
import Innerfooter from '../common/innerfooter';
import Changepwd from '../modals/Changepwd';
import Changemail from '../modals/Changemail';
import Changenum from '../modals/Changenum';
import { useDispatch, useSelector } from 'react-redux';
import { Statuses } from '../Redux/action';
import { toast } from 'react-toastify';
function Memberinfo() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.admin)
  const [isChecked, setIsChecked] = useState(false);
  const[editpwd,setEditpwd]= useState(false)
  const[editmail,setEditmail]= useState(false)
  const[editnum,setEditnum]= useState(false)
  const handleChange = checked => {
  
    if (checked ==  true){
      dispatch(Statuses({
        themeStatus: "light",
    }),
    
      );
      localStorage.setItem('theme', 'light');
      toast.success("Theme changed to Light")
    }
    else{
      dispatch(Statuses({
        themeStatus: "dark",
    }),
    
      );
      localStorage.setItem('theme', 'dark');
      toast.success("Theme changed to dark")
    }
  };
  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>

              <Pricecard />
              <div className={`${cascading.innercontent} ${cascading.memberinfo} `}>
                <p className={`${cascading.pagehead} mb-4`}>Member Information</p>
                <div className={`${cascading.membercard}`}>
                  <div className={`${cascading.toprow}`}>
                    <div className={`${cascading.leftpf}`}>
                      <div className={`${cascading.pfsec}`}>
                        <div>
                          <img src={dummypf} alt='pf'className={`img-fluid ${cascading.pfimg}`}/>
                        </div>
                        <div>
                          <p className={`${cascading.label}`}>Name</p>
                          <p className={`${cascading.value}`}>Elon musk</p>
                          </div>
                      </div>
                    

                    </div>
                    <div  className={`${cascading.themeswitch}`}>
                      <span className={`${cascading.swtlabel}`}>Dark</span>
                      <Switch onChange={handleChange} checked={data.themeStatus == "light" ? true : false} 
                      handleDiameter={30}
                      borderRadius={6}
                      offColor="#2b2519"
                      onColor="#2b2519"
                      offHandleColor="#dfc78b"
                      onHandleColor="#dfc78b"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      />
                      <span className={`${cascading.swtlabel}`}>Light</span>
                      </div>

                  </div>
                  <div className={`${cascading.cardrow} row`}>
                    <div className={`col-lg-4 col-md-6 px-0`}>
                    
                      <div className={`${cascading.cardstyles}`}>
                        <p className={`${cascading.label}`}>ID</p>
                        <p className={`${cascading.value}`}>5456677</p>
                    </div>
                    <div className={`${cascading.cardstyles}`}>
                    
                        <p className={`${cascading.label}`}>Country</p>
                        <p className={`${cascading.value}`}>Korea</p>
                    </div>
                    <div className={`${cascading.cardstyles}`}>
                        <p className={`${cascading.label}`}>Member Level</p>
                        <img src={vipimg} className={`${cascading.memimg} img-fluid`} alt='Member Level'/>
                    </div>  

                    </div>
                    <div className={`col-lg-4 col-md-6 px-0`}>
                    
                    <div className={`${cascading.cardstyles}`}>
                      <p className={`${cascading.label}`}>Password</p>
                      <p className={`${cascading.value}`}>********</p>
                      <button className={`${cascading.editbtn} btn`} onClick={()=>setEditpwd(true)}>Edit</button>
                  </div>
                  <div className={`${cascading.cardstyles}`}>
                  
                      <p className={`${cascading.label}`}>Phone Number</p>
                      <p className={`${cascading.value}`}>+82 010 1234 1234</p>
                      <button className={`${cascading.editbtn} btn`} onClick={()=>setEditnum(true)}>Edit</button>
                  </div>
                  <div className={`${cascading.cardstyles}`}>
                      <p className={`${cascading.label}`}>Email</p>
                      <p className={`${cascading.value}`}>asdasd@gmail.com</p>
                      <button className={`${cascading.editbtn} btn`} onClick={()=>setEditmail(true)}>Edit</button>
                  </div>  

                  </div>
                  <div className={`col-lg-4 col-md-6 px-0`}>
                    
                    <div className={`${cascading.cardstyles}`}>
                      <p className={`${cascading.label}`}>MY USDT Address</p>
                      <p className={`${cascading.value}`}>USDT(TRC20)</p>
                  </div>
                  <div className={`${cascading.cardstyles}`}>
                  
                      <p className={`${cascading.label}`}>USDT Balance</p>
                      <p className={`${cascading.value}`}>1,000,000,000 USDT</p>
                  </div>
                  <div className={`${cascading.cardstyles}`}>
                      <p className={`${cascading.label}`}>Referral Link</p>
                      <p className={`${cascading.referal}`}>www.fafsgdgdhdjhs.com <FaCopy /></p>
                  </div>  

                  </div>
                  

                  </div>

                </div>
                
              
              </div>
              <Innerfooter/>

            </div>

          </Grid>
        </Grid>
      </div>
      {editpwd && <Changepwd show={editpwd} onClose={()=>setEditpwd(false)}/>}
      {editmail && <Changemail show={editmail} onClose={()=>setEditmail(false)}/>}
      {editnum && <Changenum show={editnum} onClose={()=>setEditnum(false)}/>}
    </>
  )
}

export default Memberinfo