import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Pricecard from '../components/pricecard';
import Table from 'react-bootstrap/Table';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useDemoData } from "@mui/x-data-grid-generator";
import Plancard from '../components/plancard';
import planone from '../assests/images/oneplan.svg';
import plantwo from '../assests/images/twoplan.svg';
import planthree from '../assests/images/threeplan.svg';
import planfour from '../assests/images/fourplan.svg';
import planfive from '../assests/images/fiveplan.svg';
import plansix from '../assests/images/sixplan.svg';
import Innerfooter from '../common/innerfooter';
function Subscription() {
  const [plantab, setPlantab] = useState("Auto Trading");
  const [btmtab, setBtmtab] = useState(true)
  const columns = [
    // { field: 'sno', headerName: '#', width: 100 },
    { field: 'trading', headerName: 'Trading Exchange', minWidth: 100, flex: 1 },
    { field: 'recommended', headerName: 'Recommended subscription link', minWidth: 150, flex: 1 },
    { field: 'amount', headerName: 'Trading Amount ', minWidth: 100, flex: 1 },
    { field: 'time', headerName: 'Trading Time', minWidth: 150, flex: 1 },
    { field: 'api', headerName: 'Exchange API', minWidth: 150, flex: 1 },
    { field: 'expiry', headerName: 'Subscription Expiration Time', minWidth: 150, flex: 1 },



  ];

  const rows = [
    { id: 0, trading: 'Bitget,Binance,OKX,Bybit', recommended: 'Sign up for the exchange through the recommended link provided by M-SCORE', amount: 'Free', time: 'Free', api: 'The UID account of the trading exchange uses only one of automatic and manual transactions ', expiry: 'Automatic suspension of signal and API transactions at the expiration of the period' },




  ];
  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 1000,
    maxColumns: 4
  });
  const inputElement = useRef();
  function StyledDataGrid() {
    return {
      backgroundColor: " #1E1810",
      border: 0,
      color: '#DBCEB5',
      fontWeight: 400,
      fontFamily: [
        "Titillium Web"
      ].join(','),
      WebkitFontSmoothing: 'auto',
      letterSpacing: 'normal',

      '& .MuiDataGrid-columnsContainer': {
        backgroundColor: '#fafafa',
      },

      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        // borderRight: "1px solid #f0f0f0 "
        color: "#F5C246",
        borderRight: "1px solid transparent ",
      },
      '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: "1px solid transparent ",
      },
      '& .MuiDataGrid-cell': {
        color:
          'rgba(0,0,0,.85)',

      },
      '& .MuiPaginationItem-root': {
        borderRadius: 0,
      },
      '& .MuiDataGrid-columnHeaders': {
        background: ' #C8AD7929',
        borderBottom: "1px solid #3D3728 ",
      },
      '& .MuiDataGrid-footerContainer': {
        display: "flex",
        justifyContent: "end",
        borderColor: "#3D3728"
      },
      '& .MuiDataGrid-cellContent': {
        color: "#DBCEB5",
      },
      '& .MuiPaginationItem-text': {
        color: "#DBCEB5"
      },
      "& ::-webkit-scrollbar": {
        height: '7px'
      },




    }
  };


  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (

      <Pagination
        color="standard"
        shape="rounded"
        page={page + 1}
        // className={`${styles.pagecustom}`}
        count={pageCount}
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />

    );
  }

  const [plandetail, setPlandetail] = useState([
    {
      img: planone,
      head: "Profit Share",
      subhead: "No Subscription fee",
      price: "40%",
      desc: "commission of USDT revenue per month",
      type: "Auto Trading",
      status: "active",
    },
    {
      img: plantwo,
      head: "3-month",
      subhead: "Subscription",
      price: "1000",
      desc: "",
      type: "Indigator",
      status: "notactive",
    },
    {
      img: planthree,
      head: "6-month",
      subhead: "Subscription",
      price: "5,000",
      desc: "",
      type: "Indigator",
      status: "notactive",
    },
    {
      img: planfour,
      head: "12-month",
      subhead: "Subscription",
      price: "9,000",
      desc: "",
      type: "Indigator",
      status: "notactive",
    },
    {
      img: planfive,
      head: "24-month ",
      subhead: "Subscription",
      price: "17,000",
      desc: "",
      type: "Indigator",
      status: "notactive",
    },
    {
      img: plansix,
      head: "Demo",
      subhead: "Trading",
      price: "Free",
      desc: "",
      type: "Demo Trading",
      status: "notactive",
    },

  ])

  const [usageorder, setUsageorder] = useState([
    {
      head: "Subscribe to Bitget Exchange via link address",
      desc: `Did you join the Bitget Exchange through the address link of the Bitget Exchange provided by  M-SCORE?  (Bitget UID that are not subscribed in the link are not connected to AI API)`,
      type: "Auto Trading",
    },
    {
      head: "Application of AI API after issuing Bitget API",
      desc: "Did Bitget Exchange issue 4 kinds of APIs?( If you haven't issued an API, please follow the guide you enter in API issuance and M-SCORE) (Click Guide)",
      type: "Auto Trading",
    },
    {
      head: "USDT Deposit to Trading Bitget Exchange",
      desc: "The account will be automatically deleted if you do not deposit the USDT you want to trade with your Bitget Exchange account within 7 days.",
      type: "Auto Trading",
    },
    {
      head: "AI Future Trading Auto Trading Begins",
      desc: "Trading will begin automatically when you sign up, issue and apply API, and deposit USDT EX through bitget link address.",
      type: "Auto Trading",
    },
    {
      head: "Sign up for membership via recommendation link by exchange provided by M-SCORE",
      desc: "( Trading is not possible if you do not sign up through a link address )",
      type: "Indigator",
    },
    {
      head: "Create APIs on joined exchanges",
      desc: "Please enter the issued API correctly into the M-SCORE platform.",
      type: "Indigator",
    },
    {
      head: "USDT Deposit to Trading Exchange",
      desc: "Apply API to the platform and deposit USDT into the cryptocurrency exchange's gift wallet",
      type: "Indigator",
    },
    {
      head: "Payment of signal products to M-SCORE platform",
      desc: "Pay for your subscription to the designated USDT (TRC20) address of the M-SCORE platform.If the payment is made at the wrong address, you cannot apply for a subscription.",
      type: "Indigator",
    },
    {
      head: "Subscribe to Bitget Exchange via link address",
      desc: "Did you join the Bitget Exchange through the address link of the Bitget Exchange provided by  M-SCORE? (Bitget UIDs that are not subscribed  in the link are not connected to AI API)",
      type: "Demo Trading",
    },
    {
      head: "Application of AI API after issuing Bitget API",
      desc: "Did Bitget Exchange issue 4 kinds of API? (If you haven't issued an API, please follow the guide you enter in API issuance and M-SCORE) (Click Guide)",
      type: "Demo Trading",
    },
    {
      head: "USDT Deposit to Trading Bitget Exchange",
      desc: "The account will be automatically deleted if you do not deposit the USDT you want to trade with your Bitget Exchange account within 7 days.",
      type: "Demo Trading",
    },
    {
      head: "Apply for Virtual AI Auto Trading Products",
      desc: "When membership, API issuance, and applied via Bitget Link address, the administrator receives the application and starts trading the demo. Real-time view of virtual returns and losses on member dashboards",
      type: "Demo Trading",
    },
  ])
  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>

              <Pricecard />
              <div className={`${cascading.innercontent} ${cascading.subscriptionpage} `}>
                <p className={`${cascading.pagehead} mb-4`}>Product Subscription</p>
                <div className={`${cascading.paymenttabsec}`}>
                  <div className={`${cascading.paymenttab} mb-4`}>
                    <div className={plantab == "Auto Trading" ? `${cascading.tabstyle} ${cascading.activetabstyle}` : `${cascading.tabstyle}`} onClick={() => setPlantab('Auto Trading')}>
                      <p>AI AUTO TRADING</p>
                    </div>
                    <div className={plantab == "Indigator" ? `${cascading.tabstyle} ${cascading.activetabstyle}` : `${cascading.tabstyle}`} onClick={() => setPlantab("Indigator")}>
                      <p>INDIGATOR TARGET TRADING</p>
                    </div>
                    <div className={plantab == "Demo Trading" ? `${cascading.tabstyle} ${cascading.activetabstyle}` : `${cascading.tabstyle}`} onClick={() => setPlantab('Demo Trading')}>
                      <p>AI AUTO DEMO TRADING</p>
                    </div>
                  </div>
                  {console.log(plantab, "plantabplantab")}

                  <div className={`${cascading.innertabcontent}`}>
                    <div className={`${cascading.cardrow} row`}>
                      {plandetail.filter((item => item.type == plantab)).map((e) => (
                        <div className={plantab == "Indigator" ? `col-md-6 mb-4` : `col-md-12 mb-4`}>
                          <Plancard datas={e} />
                        </div>
                      ))}

                    </div>

                    <div className={`${cascading.btmtabsec}`}>
                      <div className={`${cascading.tabsec}`}>
                        <ul>
                          <li className={btmtab && `${cascading.activetab}`} onClick={() => setBtmtab(true)}>
                            {plantab == "Auto Trading" && "M-Score Postpaid product"}
                            {plantab == "Indigator" && "Prepaid Payment Product"}
                            {plantab == "Demo Trading" && "Non-Payment Goods"}
                          </li>
                          <li className={!btmtab && `${cascading.activetab}`} onClick={() => setBtmtab(false)}>Trading Usage Order</li>
                        </ul>

                      </div>
                      <div className={`${cascading.tabcontent}`}>
                        {btmtab ? <>
                          {plantab == "Auto Trading" &&
                            <>
                              <Table  bordered responsive className={`${cascading.detailtable}`}>
                                <thead>
                                  <tr>
                                    <th>Member Level</th>
                                    <th>Joining Fee</th>
                                    <th>Operating Period</th>
                                    <th>Level Condition
                                      ( EX-Deposit Amount )</th>
                                    <th>Level Condition
                                      ( EX-Deposit Amount )</th>
                                    <th>Level Condition
                                      ( EX-Deposit Amount )</th>
                                    <th>Status</th>
                                    <th>TRD GAS Deduction
                                      Conditions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>VVIP</td>
                                    <td>Free</td>
                                    <td>Unlimited</td>
                                    <td>Min - 100,000 USDT</td>
                                    <td rowSpan={2}>1,000 USDT OVER</td>
                                    <td rowSpan={2}>Recharge TRD Power within 7 days
                                      after the first trading starts
                                    </td>
                                    <td>20%</td>
                                    <td rowSpan={2}>he TRD Power amount charged in advance is deducted based on the tier rates as the USDT amount increases

                                      When TRD Power reaches 0 USDT, API will be  automatically suspended.

                                      When the TRD Power value is above 0, the API will continue trading without suspension.
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>VVIP</td>
                                    <td>Free</td>
                                    <td>Unlimited</td>
                                    <td>Max - 100,000 USDT
                                      Min - 15,000 USDT
                                    </td>

                                    <td>20%</td>

                                  </tr>

                                </tbody>
                              </Table>
                            </>


                          }
                          {plantab == "Indigator" &&
                            <>
                              <div id="styletable">
                                <DataGrid
                                  ref={inputElement}
                                  {...data}
                                  disableColumnMenu
                                  rows={rows}

                                  sx={{
                                    ...StyledDataGrid(),
                                  }}
                                  columns={columns}
                                  getRowHeight={() => 'auto'}
                                  components={{
                                    Pagination: CustomPagination,
                                  }}
                                // slots={{
                                //   footer: CustomPagination,
                                // }}



                                />
                              </div>

                            </>



                          }
                          {plantab == "Demo Trading" &&
                            <div className={`${cascading.textcard}`}>
                              <p>Non-subscription and revenue-sharing demo products</p>
                              <p>Equivalent to the figure traded by the virtual 100,000 USDT.</p>


                            </div>



                          }


                        </> :

                          <>
                            <div className={`${cascading.detailcardsec} row mx-0`}>
                              {usageorder.filter((item => item.type == plantab)).map((e) => (
                                <div className={`col-lg-3 col-md-6 ${cascading.card}`}>
                                  <p className={`${cascading.detailhead}`}>{e.head}</p>
                                  <p className={`${cascading.detaildesc}`}>{e.desc}</p>
                                </div>
                              ))}


                            </div>

                          </>

                        }
                      </div>
                       <div className={`${cascading.applybtnsec} mt-4`}>
                        <button className={`${cascading.applybtn}`}>Apply</button>
                        
                       </div>
                    </div>


                  </div>


                  {/* {plantab == "Indigator" &&
                    <div className={`${cascading.innertabcontent}`}>
                      <div className={`${cascading.cardrow} row`}>
                        {plandetail.filter((item => item.type == "Indigator")).map((e) => (
                          <div className={`col-md-6 mb-4`}>
                            <Plancard datas={e} />
                          </div>
                        ))}

                      </div>


                    </div>

                  }
                  {plantab == "Demo Trading" &&
                    <div className={`${cascading.innertabcontent}`}>
                      <div className={`${cascading.cardrow} row`}>
                        {plandetail.filter((item => item.type == "Demo Trading")).map((e) => (
                          <div className={`col-md-6 mb-4`}>
                            <Plancard datas={e} />
                          </div>
                        ))}

                      </div>


                    </div>

                  } */}

                </div>

              </div>
              <Innerfooter/>

            </div>

          </Grid>
        </Grid>
      </div>

    </>
  )
}

export default Subscription