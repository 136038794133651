import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Innerfooter from '../common/innerfooter';
import Pricecard from '../components/pricecard';
import Tableview from '../components/tableview';
import ReactApexChart from 'react-apexcharts';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useDemoData } from "@mui/x-data-grid-generator";
import symbols from '../assests/images/coinimg.png';
import vipimg from '../assests/images/vip.png';
import koreaflag from '../assests/images/korea.png';
import StyledDataGrid from '../common/StyleDataGrid';
function Tradingrank() {
  const datamap = {     
    series: [{
      name: 'series1',
      data: [25, 15, 75, 35, 55, 25, 100, 25,55]
    }],
    options: {
      colors:['#48B16A'],
      chart: {
        height: 50,
        type: 'area',
        zoom: {
          enabled: false,
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight',
        width:1
      },
      // fill: {
      //   colors: ['#48B16A', '#48B16A00']
      // },
      fill: {
        type: "gradient",
        gradient: {
          shade: 'dark',
    type: "vertical",
    shadeIntensity: 0.5,
    gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
    inverseColors: true,
    opacityFrom: 1,
    opacityTo: .3,
    stops: [0, 50, 100],
    colorStops: []
        }
      },
  
      xaxis: {
        type: 'Lists',
        categories: ["Jan", "Feb","Mar","Apr", "May","Jun", "July","Aug", "Sep"]
      },
     
      
      tooltip: {
        enabled: false,
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
    },
  
  
  };
  const columns = [
    { field: 'sno', headerName: '#', minWidth: 100 ,flex:1},
    { field: 'ids', headerName: 'Id', minWidth: 100 ,flex:1},
    { field: 'grade', headerName: 'Grade', minWidth: 100,flex:1,renderCell: (params) =><div className={`${cascading.vipimg}`}><img src={vipimg} className={`img-fluid me-2`}/><span>{params.value}</span> </div> },
    { field: 'country', headerName: 'Country', minWidth: 100, flex:1,renderCell: (params) =><div className={`${cascading.flagsec}`}><img src={koreaflag} className={`img-fluid me-2`}/><span>{params.value}</span> </div>},
    { field: 'symbol', headerName: 'Symbol', minWidth: 100,flex:1, renderCell: (params) =><div className={`${cascading.symsec}`} ><img src={symbols} className={`img-fluid me-2`}/><span>{params.value}</span> </div>},
    { field: 'trading', headerName: 'Trading', minWidth: 100, flex:1},
    { field: 'trade', headerName: 'Trade', minWidth: 100,flex:1 },
    { field: 'traded', headerName: 'Traded', minWidth: 150,flex:1 },
    { field: 'tradeusdt', headerName: 'Trade in USDT', minWidth: 100, flex:1},
    { field: 'usdt', headerName: 'USDT in usdt', minWidth: 100,flex:1 },
    { field: 'usdtex', headerName: 'USDT in usdt', minWidth: 150,flex:1 },
    { field: 'pnl', headerName: 'pnL', minWidth: 100,flex:1 },
    {
      field: 'graph', headerName: '', minWidth: 150,flex:1, renderCell: (params) =>
      <div id="chartgreen">
      <ReactApexChart  options={datamap.options} series={datamap.series} type="area" height={190} width={130} />
    </div>  

    },


  ];

  const rows = [
    { id: 0, sno: 1, ids: 'Ela-231541', grade: 'VIP', country: 'Korea', symbol: 'USDT', trading: '1000 USDT', trade: 'A Gift', traded: '1,000,000', tradeusdt: '1,000,000', usdt: "+100.00", usdtex: "50", pnl: "120%", graph: "1" },
   


  ];
  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 1000,
    maxColumns: 4
  });
  const inputElement = useRef();



  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (

      <Pagination
        color="standard"
        shape="rounded"
        page={page + 1}
        // className={`${styles.pagecustom}`}
        count={pageCount}
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />

    );
  }


  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>

              <Pricecard />
              <div className={`${cascading.innercontent} ${cascading.rankpage}`}>
                <p className={`${cascading.pagehead}`}>MEMBER TRADING RANK</p>
                <div className={`${cascading.tablesec} mt-4`}>
                  <div id="styletable">
                    <DataGrid
                      ref={inputElement}
                      {...data}
                      disableColumnMenu
                      rows={rows}
                      rowHeight={100}
                      sx={{
                        ...StyledDataGrid(),
                      }}
                      columns={columns}

                      components={{
                        Pagination: CustomPagination,
                      }}
                    // slots={{
                    //   footer: CustomPagination,
                    // }}



                    />
                  </div>

                </div>
              </div>

             <Innerfooter/>
            </div>

          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Tradingrank