import React, { useState } from 'react'
import Grid from '@mui/material/Grid';
import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Pricecard from '../components/pricecard';
import caticon from '../assests/images/caticon.png';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FaChevronRight } from "react-icons/fa6";
import blogimg from '../assests/images/blog.png'
import Innerfooter from '../common/innerfooter';
function Blog() {
 const[catactive,setCatactive]= useState("Mobile Apps")
 console.log(catactive,"catactive")
  const [catdata, setCatdata] = useState([{
    catname: "Mobile Apps",
    caticon: caticon
  },
  {
    catname: "Artificial Intelligence",
    caticon: caticon
  },
  {
    catname: "Games",
    caticon: caticon
  },
  {
    catname: "Cryptocurrency",
    caticon: caticon
  },
  {
    catname: "Web3",
    caticon: caticon
  },
  {
    catname: "Software",
    caticon: caticon
  },
  {
    catname: "Metaverse",
    caticon: caticon
  },
  {
    catname: "Tokenamics",
    caticon: caticon
  },
  {
    catname: "Mobile Apps",
    caticon: caticon
  },
  {
    catname: "Artificial Intelligence",
    caticon: caticon
  },
  {
    catname: "Games",
    caticon: caticon
  },
  {
    catname: "Cryptocurrency",
    caticon: caticon
  },
  {
    catname: "Web3",
    caticon: caticon
  },
  {
    catname: "Software",
    caticon: caticon
  },
  {
    catname: "Metaverse",
    caticon: caticon
  },
  {
    catname: "Tokenamics",
    caticon: caticon
  },])
  const [blogdata, setBlogdata] = useState([
    {
      blogimg: blogimg,
      head: 'What Lorem Ipsum is simply dummy text of the printing and typesetting industry?',
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining.Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
      blogimg: blogimg,
      head: 'What Lorem Ipsum is simply dummy text of the printing and typesetting industry?',
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining.Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
      blogimg: blogimg,
      head: 'What Lorem Ipsum is simply dummy text of the printing and typesetting industry?',
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining.Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
      blogimg: blogimg,
      head: 'What Lorem Ipsum is simply dummy text of the printing and typesetting industry?',
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining.Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
      blogimg: blogimg,
      head: 'What Lorem Ipsum is simply dummy text of the printing and typesetting industry?',
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining.Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
      blogimg: blogimg,
      head: 'What Lorem Ipsum is simply dummy text of the printing and typesetting industry?',
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining.Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
  ])
  const options = {
    items: 1,
    navText: ["<div class='nav-button owl-prev'>‹</div>", "<div class='nav-button owl-next'>›</div>"]
  };

  const listmap = (arr, n) => {
    const array = arr.slice();
    const chunks = [];
    while (array.length) chunks.push(array.splice(0, n));
    return chunks;
  };
  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>

              <Pricecard />
              <div className={`${cascading.innercontent}`}>
                <p className={`${cascading.pagehead}`}>Blog</p>

                <div className={`${cascading.blogpage} mt-4`}>
                  <div className={`${cascading.tabsec}`}>
                    <OwlCarousel className={`owl-theme ${cascading.carousel}`} loop margin={10} nav {...options}>
                      {listmap(catdata, 8).map((row, i) => (
                        <div className='item'>
                          <ul className={`nav nav-pills mb-3 ${cascading.navlist}`} id="pills-tab" role="tablist">
                            {row.map((val, i) => (
                              <li className={`nav-item ${cascading.navitem}`} role="presentation">
                                <button className={catactive == val.catname ? `nav-link active ${cascading.navlink} ${cascading.navlinkactive}`: `nav-link active ${cascading.navlink}`} id={`pills-home-tab${i}`} data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={()=>setCatactive(val.catname)}>
                                  <div className={`${cascading.leftsec}`}>
                                    <img src={val.caticon} className={`img-fluid ${cascading.caticon}`} alt='icon' />
                                    <p className={`${cascading.catname}`}>{val.catname}</p>
                                  </div>
                                  <FaChevronRight />
                                </button>
                              </li>
                            ))}

                          </ul>


                        </div>
                      ))
                      }


                    </OwlCarousel>

                  </div>
                  <div className={`${cascading.tabbtmsec} mt-4`}>
                    <div class="tab-content" id="pills-tabContent">
                      <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className={`${cascading.trendsec}`}>
                          <div className={`row ${cascading.trendrow}`}>
                            <div className={`col-lg-5 mb-4`}>
                              <img src={blogimg} className={`img-fluid ${cascading.trendimg}`} alt='blogimg' />
                            </div>
                            <div className={`col-lg-7`}>
                              <p className={`${cascading.head}`}>
                                What Lorem Ipsum is simply dummy text of the printing and
                                typesetting industry?
                              </p>
                              <p className={`${cascading.desc}`}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                              </p>

                            </div>

                          </div>
                          <div className={`${cascading.blogcardsec}`}>

                            <div className={`row`}>
                              {blogdata.map((e) => (
                                <div className={`col-lg-4 col-md-6 mb-4`}>
                                  <div className={`${cascading.blogcard}`}>
                                    <img src={e.blogimg} className={`img-fluid ${cascading.blogimg}`} alt='blogimg' />
                                    <div className={`${cascading.contentsec}`}>
                                      <p className={`${cascading.head}`}>
                                       {e.head}
                                      </p>
                                      <p className={`${cascading.desc}`}>
                                      {e.desc}
                                      </p>
                                    </div>

                                  </div>

                                </div>

                              ))}


                            </div>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>


                </div>



              </div>
              <Innerfooter/>



            </div>

          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Blog;