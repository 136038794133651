import React, { useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import Select from 'react-select';
import cascading from '../assests/scss/login.module.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loginControl } from '../Redux/action';
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
function Login(props) {
    const dispatch = useDispatch();
    const history = useNavigate();
    const [step, setStep] = useState('login')
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordType, setPasswordType] = useState("password");
    const handleLogin = () => {
        if (username === 'mscore@gmail.com' && password === 'Test@123') {
            localStorage.setItem('loginstatus', 'login');
            dispatch(loginControl({
                loginStatus: "login"
            }),


            );
            history('/community');

            toast.success("Login Succesfully");
        }
        else {
            alert('Invalid credentials. Please try again.');
        }
    };
    const togglePassword = () => {
        // 
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    return (
        <div className={`${cascading.loginmodal}`}>
            <Offcanvas show={props.show} onHide={props.onHide} backdrop="static" placement='top' className={`${cascading.offcanvas}`}>
                <div className={`container`}>


                    <Offcanvas.Header closeButton>

                    </Offcanvas.Header>
                    {step == 'login' &&
                        <>
                            <Offcanvas.Body className={`${cascading.bodyrow}`}>
                                <div className={`row`}>
                                    <div className={`col-lg-5 ${cascading.leftcontentsec} mb-4`}>
                                        <div className={`row`}>
                                            <p className={`${cascading.head}`}>LAPARRAL JOIN MEMBERSHIP
                                            </p>
                                            <p className={`${cascading.desc}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

                                        </div>


                                    </div>
                                    <div className={`col-lg-7 ${cascading.rightcontentsec}`}>
                                        <div className={`row`}>
                                            <div className={`col-3  mb-3`}>
                                                <p className={`${cascading.labelname}`}>Email or Mobile no.</p>
                                            </div>
                                            <div className={`col-9 mb-3`}>
                                                <input type="text" className={`form-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="email" value={username}
                                                    onChange={(e) => setUsername(e.target.value)} />
                                            </div>

                                        </div>
                                        <div className={`row`}>
                                            <div className={`col-3  mb-3`}>
                                                <p className={`${cascading.labelname}`}>Password</p>
                                            </div>
                                            <div className={`col-9 mb-3 ${cascading.pwdinpt}`}>
                                                <div className={`${cascading.inputrow} `}>
                                                    <div className={`input-group`}>
                                                        <input type={passwordType} className={`form-control`}  placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                                        <span  className={`input-group-text`} onClick={() => togglePassword()}>  {passwordType === "password" ? <FaEyeSlash /> : <FaEye />}</span>
                                                    </div>

                                                </div>
                                                {/* <input type={passwordType} className={`form-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Password" value={password}
                                                    onChange={(e) => setPassword(e.target.value)} />
                                                <span  className={`input-group-text`} onClick={() => togglePassword()}>  {passwordType === "password" ? <FaEyeSlash /> : <FaEye />}</span> */}
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </Offcanvas.Body>
                            <hr className={`${cascading.linehr}`} />
                            <div className={`row ${cascading.canfooter}`}>
                                <div className={`col-lg-6`}>
                                    <p className={`${cascading.forgot}`} onClick={() => setStep('reset1')}>Forgot Password ?</p>
                                </div>

                                <div className={`col-lg-6 ${cascading.btnsec}`}>
                                    <Button className={`btn ${cascading.loginbtn}`}>Cancel</Button>
                                    <Button className={`btn ${cascading.regbtn}`} onClick={() => handleLogin()}>Login</Button>

                                </div>

                            </div>
                        </>
                    }
                    {step == 'reset1' &&
                        <>
                            <Offcanvas.Body className={`${cascading.bodyrow}`}>
                                <div className={`row`}>
                                    <div className={`col-lg-5 ${cascading.leftcontentsec} mb-4`}>
                                        <div className={`row`}>
                                            <p className={`${cascading.head}`}>Password Recovery
                                            </p>
                                            <p className={`${cascading.desc}`}>Your temporary password will be sent to
                                                your subscribed email.
                                            </p>

                                        </div>


                                    </div>
                                    <div className={`col-lg-7 ${cascading.rightcontentsec}`}>
                                        <div className={`row`}>
                                            <div className={`col-3  mb-3`}>
                                                <p className={`${cascading.labelname}`}>Enter Email : </p>
                                            </div>
                                            <div className={`col-9 mb-3`}>
                                                <input type="text" className={`form-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="email" />
                                                <p className={`${cascading.hintlabel}`}>Please log in with a temporary password and change it to a new password from your account information.
                                                </p>
                                            </div>

                                        </div>


                                    </div>

                                </div>
                            </Offcanvas.Body>
                            <hr className={`${cascading.linehr}`} />
                            <div className={`row ${cascading.canfooter}`}>


                                <div className={`col-lg-12 ${cascading.btnsec}`}>
                                    <Button className={`btn ${cascading.regbtn}`} onClick={() => setStep('reset2')}>Sent</Button>

                                </div>

                            </div>
                        </>
                    }
                    {step == 'reset2' &&
                        <>
                            <Offcanvas.Body className={`${cascading.bodyrow}`}>
                                <div className={`row`}>
                                    <div className={`col-lg-5 ${cascading.leftcontentsec} mb-4`}>
                                        <div className={`row`}>
                                            <p className={`${cascading.head}`}>Enter passwword

                                            </p>
                                            <p className={`${cascading.desc}`}>Your temporary password will be sent to
                                                your subscribed email.

                                            </p>

                                        </div>


                                    </div>
                                    <div className={`col-lg-7 ${cascading.rightcontentsec}`}>
                                        <div className={`row`}>
                                            <div className={`col-3  mb-3`}>
                                                <p className={`${cascading.labelname}`}>Enter Passwprd : </p>
                                            </div>
                                            <div className={`col-9 mb-3`}>
                                                <input type="text" className={`form-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="email" />
                                                <p className={`${cascading.hintlabel}`}>Please log in with a temporary password and change it to a new password from your account information.

                                                </p>
                                            </div>

                                        </div>


                                    </div>

                                </div>
                            </Offcanvas.Body>
                            <hr className={`${cascading.linehr}`} />
                            <div className={`row ${cascading.canfooter}`}>


                                <div className={`col-lg-12 ${cascading.btnsec}`}>
                                    <Button className={`btn ${cascading.loginbtn}`}>Cancel</Button>
                                    <Link to="/community" className={`btn ${cascading.regbtn}`}>Login</Link>

                                </div>

                            </div>
                        </>
                    }


                </div>
            </Offcanvas>
        
        </div>
    )
}

export default Login