import React, { useState } from 'react';
import cascading from '../assests/scss/pricecard.module.scss'
import coinimg from '../assests/images/coinimg.png';


function Pricecard() {
    const [selectedOption, setSelectedOption] = useState(null);
    const [pricevalue,setPricevalue] = useState([{
        volume:'27,582.07',
        price:"$ 27,582.07",
        range:"up"
    },
    {
        volume:'24h Change',
        price:"$ 27,582.07",
        range:"up"
    },
    {
        volume:'24h High',
        price:"$ 27,582.07",
        range:"down"
    },
    {
        volume:'24h Low',
        price:"$ 27,582.07",
        range:"down"
    },{
        volume:'24h Volume(BTC)',
        price:"$ 27,582.07",
        range:"down"
    },
    {
        volume:'24h Volume(USDT)',
        price:"$ 27,582.07",
        range:"down"
    }])
    return (
        <div className={`${cascading.pricecard}`}>
            <div className={`${cascading.coinchange}`}>
                <div className={`${cascading.selectrow}`}>
                    <img src={coinimg} className={`${cascading.coinimg}`} alt='img' />
                    <p className={`${cascading.coinname}`}>USDT/ BTC</p>
                    <p className={`${cascading.price}`}>Tether Price</p>

                </div>
                <button className={`${cascading.minibtn} mt-2`}>Futures</button>

            </div>
            <div className={`${cascading.pricescrollsec}`}>
                {pricevalue.map((e) => (
                    <div className={`${cascading.pricesec}`}>
                        <p className={`${cascading.label}`}>{e.volume}</p>
                        <p className={e.range == "up" ? `${cascading.price} ${cascading.up}` : e.range == "down" ? `${cascading.price} ${cascading.down}` :`${cascading.price}` }>{e.price}</p>
                    </div>

                ))}

            </div>
            <div className={`${cascading.pfsec}`}>
                <div className={`${cascading.firstrow}`}>
                <p className={`${cascading.labelname}`}>Ex Name : <span>Bitgen</span> </p>   
                <p className={`${cascading.labelname}`}>UID : <span>1234MS000</span> </p>   
                </div>
                <p className={`${cascading.labelname}`}>M-Score : <span>MSC-1234</span> </p>   
            </div>


        </div>
    )
}

export default Pricecard