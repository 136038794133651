import React, { useState } from 'react';
import cascading from '../assests/scss/all.module.scss'
import attachfile from '../assests/images/attachfile.svg';
import attachfile1 from '../assests/images/image.svg';
import pfimg from '../assests/images/dummypf.png';
function Createpost(props) {

    return (
        <>
            <div className={`${cascading.createpost}`}>
                <div className={`${cascading.pfsec}`}>
                    <img 
                    // src={props.img}
                      src={pfimg}
                     className={`${cascading.pfimg} img-fluid`} alt='' />
                    <p className={`${cascading.name}`}>People</p>
                </div>
                <div className={`${cascading.titlesec}`}>
                    <p className={`${cascading.title}`}>Create Post</p>
                </div>
                <div className={`${cascading.descbox} my-4 mx-4`}>
                    <div className={`${cascading.inputsec}`}>
                        <textarea id="w3review" name="w3review" rows="4" className={`${cascading.descinput}`} cols="50" placeholder='Please fill out the contents. It can be written up to 500 characters.'>
                        </textarea>
                    </div>
                    <div className={`${cascading.btnsec}`}>

                        <img src={attachfile1} className={`${cascading.attachimg} img-fluid`} alt='attachfile' />
                        <img src={attachfile} className={`${cascading.attachimg} img-fluid`} alt='attachfile' />
                    </div>

                </div>
                <div className={`${cascading.btmsec} px-4`}>
                    <div className={`${cascading.notesec}`}>
                        <p className={`${cascading.note}`}>Uploaded posts will be registered after administrator approval due to spam advertising.</p>
                    </div>
                    <div className={`${cascading.btnsec}`}>
                        <button className={`btn ${cascading.themebtn1}`} >Cancel</button>
                        <button className={`btn ${cascading.themebtn}`}>Post</button>
                    </div>

                </div>

            </div>
        </>


    )
}

export default Createpost