import React, { useState } from 'react'
import Grid from '@mui/material/Grid';
import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Pricecard from '../components/pricecard';
import { CiSearch } from 'react-icons/ci';
import Innerfooter from '../common/innerfooter';

function Notices() {

  const [searchTerm, setSearchTerm] = useState('');

  const [noticed, setNoticed] = useState([
    {
      questions: "How to request new features ?",
      date: "08 Jan 2024",

    },
    {
      questions: "Paxful social support channels ?",
      date: "08 Jan 2024",

    },
    {
      questions: "General",
      date: "08 Jan 2024",

    },
    {
      questions: "How to connect support ?",
      date: "08 Jan 2024",

    },
    {
      questions: "How to request new features ?",
      date: "08 Jan 2024",

    },
    {
      questions: "How to request new features ?",
      date: "08 Jan 2024",

    },
    {
      questions: "Paxful social support channels ?",
      date: "08 Jan 2024",

    },
    {
      questions: "General",
      date: "08 Jan 2024",

    },
    {
      questions: "How to connect support ?",
      date: "08 Jan 2024",

    },
    {
      questions: "How to request new features ?",
      date: "08 Jan 2024",

    },
    {
      questions: "How to request new features ?",
      date: "08 Jan 2024",

    },
    {
      questions: "Paxful social support channels ?",
      date: "08 Jan 2024",

    },
    {
      questions: "General",
      date: "08 Jan 2024",

    },
    {
      questions: "How to connect support ?",
      date: "08 Jan 2024",

    },
    {
      questions: "How to request new features ?",
      date: "08 Jan 2024",

    },
  ])
  const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
  };

  const filteredData = noticed.filter(item =>
      item.questions.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>

              <Pricecard />
              <div className={`${cascading.innercontent} ${cascading.noticespage}`}>
                <div className={`${cascading.noticescard}`}>
                  <div className={`${cascading.toprow}`}>
                    <p className={`${cascading.head}`}>Notices</p>
                    <div className={`${cascading.inputinside}`}>
                      <input
                        type="text"
                        placeholder="Enter Search word"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                         <CiSearch />
                    </div>

                  </div>
                  <table className={`${cascading.noticelists}`}>
                    <tr>
                      <th>Title</th>
                      <th>Date</th>
                    </tr>
                    {filteredData.map((e) => (
                      <tr>
                        <td>{e.questions}</td>
                        <td>{e.date}</td>
                      </tr>
                    ))}


                  </table>

                </div>


              </div>

              <Innerfooter/>
            </div>

          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Notices