import React, { useState } from 'react'
import Grid from '@mui/material/Grid';
import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Pricecard from '../components/pricecard';
// import cardicon from '../assests/images/cardicon.png';
import cardicon from '../assests/images/cardiconblack.svg'
import Innerfooter from '../common/innerfooter';
function Tradingrank() {
  const [balancevalue, setBalancevalue] = useState([{
    coin: "USDT",
    balance: "3,000,000",
    type:"Deposit"
  },
  {
    coin: "USDT",
    balance: "3,000,000",
    type:"Withdraw"
  },
  {
    coin: "USDT",
    balance: "3,000,000",
    type:"Assets"
  }])
  const [information, setInformation] = useState([{
    img:cardicon,
    name:"Mscore",
    label: "ID",
    value:"Jogogo1234",
  },
  {
    img:cardicon,
    name:"Exhange",
    label: "Trading Exchange",
    value:"Bitget",
  },
  {
    img:cardicon,
    name:"Exchange",
    label: "UID",
    value:"12345678",
  },
  {
    img:cardicon,
    name:"API",
    label: "API Type",
    value:"AI Auto trading",
  },
  {
    img:cardicon,
    name:"Product",
    label: "Subscribed Product",
    value:"12-Month Auto Trading",
  },
  {
    img:cardicon,
    name:"Level",
    label: "Member Level",
    value:"VVIP",
  },
  {
    img:cardicon,
    name:"Deposit",
    label: "EX – First Deposit Time",
    value:"2024-01-01 23:59:59",
  },
  {
    img:cardicon,
    name:"Trading Time",
    label: "First Trading Time",
    value:"2024-01-01 23:59:59",
  },
 ])
  return (
    <>
      <div className={`${cascading.innerpages}`}>
        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>

              <Pricecard />
              <div className={`${cascading.innercontent} ${cascading.tradeinfopage}`}>
                <p className={`${cascading.pagehead}`}>Trading information</p>
                <div className={`${cascading.balancecardrow} row mt-4`}>
                  {balancevalue.map((e) => (
                    <div className={`col-lg-4 col-md-6 mb-4`}>
                      <div className={`${cascading.balancecard}`}>
                        <p className={`${cascading.name}`}>Total {e.type}</p>
                        <div className={`${cascading.valuerow}`}>
                          <p className={`${cascading.bal}`}>{e.balance}  <span>{e.coin}</span></p>
                        </div>
                      </div>

                    </div>
                  ))}
                </div>
                <div className={`row`}>
                  {information.map((e) => (
                    <div className={`col-lg-4 col-md-6 mb-4`}>
                      <div className={`${cascading.informationcard}`}>
                        <div className={`row ${cascading.informrow} mx-0`}>
                          <div className={`col-12 ${cascading.leftside}`}>
                            <div className={`${cascading.card}`}>
                              <img src={e.img} className={`img-fluid`} alt='cardicn'/>
                              <p className={`${cascading.name}`}>{e.name}</p>
                              </div>

                            </div>
                            <div className={`col-12 ${cascading.rightside}`}>
                            <div className={`${cascading.toprow}`}>
                              <p className={`${cascading.label}`}>{e.label}</p>
                              </div>
                              <div className={`${cascading.btmrow}`}>
                              <p className={`${cascading.value}`}>{e.value}</p>
                              </div>

                            </div>

                        </div>

                      </div>
                    </div>
                  ))}


                </div>

              </div>
              <Innerfooter/>



            </div>

          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Tradingrank