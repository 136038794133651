import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import cascading from '../assests/scss/all.module.scss'

const DateRangePicker = ({ startDate, endDate, onChange }) => {
  const [startDateState, setStartDateState] = useState(startDate);
  const [endDateState, setEndDateState] = useState(endDate);

  const handleStartDateChange = date => {
    setStartDateState(date);
    onChange(date, endDateState);
  };

  const handleEndDateChange = date => {
    setEndDateState(date);
    onChange(startDateState, date);
  };

  return (
    <div className={`${cascading.rangedatepicker}`}>
      <DatePicker
        selected={startDateState}
        onChange={handleStartDateChange}
        selectsStart
        startDate={startDateState}
        endDate={endDateState}
        placeholderText="From"
        showIcon={true}
      />
      <div className='mx-3'>
        <p className={`${cascading.labels}`}>To</p>
      </div>
      <DatePicker
        selected={endDateState}
        onChange={handleEndDateChange}
        selectsEnd
        startDate={startDateState}
        endDate={endDateState}
        minDate={startDateState}
        placeholderText="To"
        showIcon={true}
      />
    </div>
  );
};

export default DateRangePicker;