import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useDemoData } from "@mui/x-data-grid-generator";
import Select from 'react-select';
import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Pricecard from '../components/pricecard';
import Tableview from '../components/tableview';
import { CiSearch } from 'react-icons/ci';
import DateRangePicker from '../components/datepicker';
import coinimg from '../assests/images/coinimg.png'
import Innerfooter from '../common/innerfooter';
import StyledDataGrid from '../common/StyleDataGrid';
const options = [
  { value: '1', label: '1Line' },
  { value: '2', label: '2Line' },
  { value: '3', label: '3Line' },
];
const options1 = [
  { value: '1', label: '1Month' },
  { value: '2', label: '2Month' },
  { value: '3', label: '3Month' },
];
const options2 = [
  { value: 'unpaid', label: 'Unpaid' },
  { value: 'completed', label: 'Payment Completed' },
];
function Commission() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };
  const [commissionvalue, setCommissionvalue] = useState([{
    name: "Number of  Subscriptions",
    value: "100,000"
  },
  {
    name: "Actual Trading",
    value: "100,000"
  },
  {
    name: "Share Membership",
    value: "100,000"
  },
  {
    name: "EX-USDT Deposit",
    value: "100,000"
  },
  {
    name: "EX-USDT Withdraw",
    value: "100,000"
  },
  {
    name: "Total Trading Volume",
    value: "100,000"
  },
  {
    name: "PnL  Quantity",
    value: "100,000"
  },
  {
    name: "TRD power Referral revenue",
    value: "100,000"
  },
])
  const columns = [
    { field: 'sno', headerName: '#', width: 100 },
    { field: 'number', headerName: 'Number', width: 100 },
    { field: 'ids', headerName: 'ID', width: 100, },
    { field: 'name', headerName: 'Name', width: 100, },
    { field: 'referral', headerName: 'Referral', width: 100, },
    { field: 'exjoin', headerName: 'EX-JOIN', type: 'Date', width: 150, },
    { field: 'mjoin', headerName: 'M-JOIN', type: 'Date', width: 150, },
    { field: 'api', headerName: 'API', width: 100, },
    { field: 'product', headerName: 'Product', width: 100, },
    { field: 'paymentamount', headerName: 'Payment Amount', width: 150, },
    { field: 'firstdeposit', headerName: 'Ex-First Deposit', width: 150, },
    { field: 'tradingstart', headerName: 'Tranding Start', width: 150, },
    { field: 'referralline', headerName: 'Referral Line', width: 150, },
    { field: 'commission', headerName: 'Commission Qunatity', width: 150, },
    { field: 'status', headerName: 'Status', width: 150, renderCell: (params) => <div className={params.value == "Completed" ? `${cascading.statuslabel} ${cascading.completed}` : params.value == "Waiting" ? `${cascading.statuslabel} ${cascading.hold}` : `${cascading.statuslabel} ${cascading.cancel}`}>{params.value}</div>, },
    { field: 'date', headerName: 'Date', width: 150, type: 'Date', },

  ];

  const rows = [
    { id: 0, sno: 1, number: '10000', ids: '#2334bdn', name: 'James', referral: 'Godoriman', exjoin: '23.10.25:23:59:59', mjoin: '23.10.25:23:59:59', api: "0", product: "Share", paymentamount: "10", firstdeposit: "10", tradingstart: "10", referralline: "10", commission: "10", status: "Deposit request", date: "23.12.31 23:59:59" },
    { id: 1, sno: 2, number: '10000', ids: '#2334bdn', name: 'James', referral: 'Godoriman', exjoin: '23.10.25:23:59:59', mjoin: '23.10.25:23:59:59', api: "0", product: "Share", paymentamount: "10", firstdeposit: "10", tradingstart: "10", referralline: "10", commission: "10", status: "Waiting", date: "23.12.31 23:59:59" },
    { id: 2, sno: 3, number: '10000', ids: '#2334bdn', name: 'James', referral: 'Godoriman', exjoin: '23.10.25:23:59:59', mjoin: '23.10.25:23:59:59', api: "0", product: "Share", paymentamount: "10", firstdeposit: "10", tradingstart: "10", referralline: "10", commission: "10", status: "Completed", date: "23.12.31 23:59:59" },

  ];
  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 1000,
    maxColumns: 4
  });
  const inputElement = useRef();
  

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (

      <Pagination
        color="standard"
        shape="rounded"
        page={page + 1}
        // className={`${styles.pagecustom}`}
        count={pageCount}
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />

    );
  }



  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>

              <Pricecard />
              <div className={`${cascading.innercontent} ${cascading.commissionpage} `}>
                <div className={`${cascading.topcardsec}`}>
                  <div className={`${cascading.toprow}`}>
                    <p className={`${cascading.pagehead}`}>Recommendation & Commission</p>
                    <p className={`${cascading.label}`}><img src={coinimg} alt="coinimg" className={`img-fluid`} /> Commission: <span className={`${cascading.value}`}>1,000,000 USDT</span></p>
                  </div>
                  <div className={`${cascading.cardsec} row mt-4 px-3`}>
                    {commissionvalue.map((e) => (
                      <div className={`col-lg-3 col-sm-6 px-0`}>
                        <div className={`${cascading.cardtsyle}`}>
                          <p className={`${cascading.name}`}> {e.name}</p>
                          <div className={`${cascading.valuerow}`}>
                            <p className={`${cascading.bal}`}>{e.value} </p>
                          </div>
                        </div>

                      </div>
                    ))}





                  </div>

                </div>


                <div className={`${cascading.tablesec} mt-4`}>
                  <div className={`${cascading.filtersec}`}>
                    <div className={`${cascading.inputinside}`}>
                      <input
                        type="text"
                        placeholder="Search..."
                      // value={searchTerm}
                      // onChange={handleSearchChange}
                      />
                      <CiSearch />
                    </div>
                    <DateRangePicker startDate={startDate} endDate={endDate} onChange={handleDateChange} />

                    <div className={`${cascading.selectfiltersec}`}>
                      <div className={`${cascading.reactselect}`}>
                        <Select
                          className='reactselect_container'
                          classNamePrefix="reactselect"
                          defaultValue={selectedOption}
                          onChange={setSelectedOption}
                          options={options}
                          placeholder="Total"
                        />
                      </div>
                      <div className={`${cascading.reactselect}`}>
                        <Select
                          className='reactselect_container'
                          classNamePrefix="reactselect"
                          defaultValue={selectedOption1}
                          onChange={setSelectedOption1}
                          options={options1}
                          placeholder="Total Share"
                        />
                      </div>
                      <div className={`${cascading.reactselect}`}>
                        <Select
                          className='reactselect_container'
                          classNamePrefix="reactselect"
                          defaultValue={selectedOption2}
                          onChange={setSelectedOption2}
                          options={options2}
                          placeholder="Total Unpaid"
                        />
                      </div>
                    </div>
                  </div>
                  <div id="styletable">
                    <DataGrid
                      ref={inputElement}
                      {...data}
                      disableColumnMenu
                      rows={rows}
                      sx={{
                        ...StyledDataGrid(),
                      }}
                      columns={columns}

                      components={{
                        Pagination: CustomPagination,
                      }}
                    // slots={{
                    //   footer: CustomPagination,
                    // }}



                    />
                  </div>

                </div>
              </div>
              <Innerfooter/>

            </div>

          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Commission