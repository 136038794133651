import React, { useState } from 'react'
import Grid from '@mui/material/Grid';
import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Pricecard from '../components/pricecard';
import adminimg from '../assests/images/inquiry.svg';
import pfimg from '../assests/images/dummypf.png';
import Innerfooter from '../common/innerfooter';

function Inquiry() {
  const[helpdetail,setHelpdetail]= useState([{
    img:pfimg,
    name:"Elon musk",
    date:"2024.01.01   23:59:59",
    desc:"Lorem ipsum dolor sit amet consectetur. Id cras justo sed ipsum fermentum faucibus at nunc. Lacus metus fusce senectus mi leo adipiscing. Nulla nunc quis sed nunc sit ac. Rhoncus urna pretium magna dolor urna. Ipsum sit pulvinar aliquam egestas. Tempus consectetur auctor gravida et. Amet eget sed leo nisi sed quis. At nec lorem dolor tristique lacus. Hendrerit arcu pretium imperdiet nibh amet eget pretium pretium vestibulum. Nibh urna mauris orci malesuada egestas eu. Nibh diam elit posuere lectus sit. Proin etiam id netus eu odio egestas."
  },
  {
    img:pfimg,
    name:"Elon musk",
    date:"2024.01.01   23:59:59",
    desc:"Lorem ipsum dolor sit amet consectetur. Id cras justo sed ipsum fermentum faucibus at nunc. Lacus metus fusce senectus mi leo adipiscing. Nulla nunc quis sed nunc sit ac. Rhoncus urna pretium magna dolor urna. Ipsum sit pulvinar aliquam egestas. Tempus consectetur auctor gravida et. Amet eget sed leo nisi sed quis. At nec lorem dolor tristique lacus. Hendrerit arcu pretium imperdiet nibh amet eget pretium pretium vestibulum. Nibh urna mauris orci malesuada egestas eu. Nibh diam elit posuere lectus sit. Proin etiam id netus eu odio egestas."
  },
  {
    img:pfimg,
    name:"Elon musk",
    date:"2024.01.01   23:59:59",
    desc:"Lorem ipsum dolor sit amet consectetur. Id cras justo sed ipsum fermentum faucibus at nunc. Lacus metus fusce senectus mi leo adipiscing. Nulla nunc quis sed nunc sit ac. Rhoncus urna pretium magna dolor urna. Ipsum sit pulvinar aliquam egestas. Tempus consectetur auctor gravida et. Amet eget sed leo nisi sed quis. At nec lorem dolor tristique lacus. Hendrerit arcu pretium imperdiet nibh amet eget pretium pretium vestibulum. Nibh urna mauris orci malesuada egestas eu. Nibh diam elit posuere lectus sit. Proin etiam id netus eu odio egestas."
  }
])

  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>

              <Pricecard />
              <div className={`${cascading.innercontent} ${cascading.inquirypage}`}>
                <div className={`${cascading.helpcard} row`}>
                  <div className={`${cascading.leftsec} col-lg-3 col-md-3`}>
                    <img src={adminimg} className={`${cascading.adminimg} img-fluid`} alt='adminimg' />
                    <div>
                      <p className={`${cascading.head}`}>Help Center</p>
                      <p className={`${cascading.label}`}>1.1 Inquiry</p>
                    </div>

                  </div>
                  <div className={`${cascading.rightside} col-lg-9 col-md-9`}>
                    <div>
                      <p className={`${cascading.head}`}>Nice to meet you. How may I help you ?</p>
                      <p className={`${cascading.desc}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to </p>
                    </div>
                    <div>
                      <button className={`${cascading.themebtn}`}>Contact us</button>
                    </div>

                  </div>
                </div>

                <div className={`mt-5`}>
                {helpdetail.map((e)=>(
                     <div className={`${cascading.helpdetailcard}`}>
                     <div className={`${cascading.toprow}`}>
                       <img src={e.img} className={`img-fluid ${cascading.pfimg}`} alt='pfimg'/>
                       <div>
                         <p className={`${cascading.name}`}>{e.name}</p>
                         <p className={`${cascading.date}`}>{e.date}</p>
                         <p className={`${cascading.desc}`}>{e.desc}</p>
                       </div>
                     
   
                     </div>
                
   
                   </div>
                ))}
                    </div>
             



                <div>

                </div>

              </div>
              <Innerfooter/>

            </div>

          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Inquiry