import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useDemoData } from "@mui/x-data-grid-generator";
import Select from 'react-select';

import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Pricecard from '../components/pricecard';
import coinlogo from '../assests/images/coinimg.png';
import flagimg from '../assests/images/flag.svg'
import Tradechart from '../components/tradechart';
// import Tradechart from '../components/TVChartContainer/index';
// import Tradechart from '../components/reacttradechart';

import Ordersection from '../components/Ordersection';
import Innerfooter from '../common/innerfooter';
import Tradesettings from '../modals/tradesettings';
import StyledDataGrid from '../common/StyleDataGrid';
function Airdrop() {
  const [settingmodal, setSettingmodal] = useState(false);
 
  const [coins, setCoins] = useState([
    {
      img:coinlogo,
      coinname:"KLAYUSDT",
      price:"-2.02%"

    },
    {
      img:coinlogo,
      coinname:"KLAYUSDT",
      price:"-2.02%"

    },
    {
      img:coinlogo,
      coinname:"KLAYUSDT",
      price:"-2.02%"

    },
    {
      img:coinlogo,
      coinname:"KLAYUSDT",
      price:"-2.02%"

    },
    {
      img:coinlogo,
      coinname:"KLAYUSDT",
      price:"-2.02%"

    },
    {
      img:coinlogo,
      coinname:"KLAYUSDT",
      price:"-2.02%"

    },
    {
      img:coinlogo,
      coinname:"KLAYUSDT",
      price:"-2.02%"

    },
    {
      img:coinlogo,
      coinname:"KLAYUSDT",
      price:"-2.02%"

    },
    {
      img:coinlogo,
      coinname:"KLAYUSDT",
      price:"-2.02%"

    },
    {
      img:coinlogo,
      coinname:"KLAYUSDT",
      price:"-2.02%"

    },
    {
      img:coinlogo,
      coinname:"KLAYUSDT",
      price:"-2.02%"

    },
    {
      img:coinlogo,
      coinname:"KLAYUSDT",
      price:"-2.02%"

    },
    {
      img:coinlogo,
      coinname:"KLAYUSDT",
      price:"-2.02%"

    },
    {
      img:coinlogo,
      coinname:"KLAYUSDT",
      price:"-2.02%"

    },
    {
      img:coinlogo,
      coinname:"KLAYUSDT",
      price:"-2.02%"

    },
    {
      img:coinlogo,
      coinname:"KLAYUSDT",
      price:"-2.02%"

    }
  ])
  const columns = [
    { field: 'sno', headerName: '#', minWidth: 100,flex:1 },
    { field: 'symbol', headerName: 'Symbol', minWidth: 100,flex:1 },
    { field: 'size', headerName: 'Size', minWidth: 150,flex:1 },
    { field: 'entryprice', headerName: 'Entry Price', minWidth: 100,flex:1 },
    { field: 'markprice', headerName: 'Mark price', minWidth: 150,flex:1 },
    { field: 'liqprice', headerName: 'Liqprice', minWidth: 150,flex:1 },
    { field: 'marginratio', headerName: 'Margin Ratio', minWidth: 150,flex:1 },
    { field: 'margin', headerName: 'Margin', minWidth: 150,flex:1 },
    { field: 'pnl', headerName: 'pnl', minWidth: 100, flex:1},
    { field: 'status', headerName: 'Close All Position', minWidth: 150,flex:1, renderCell: (params) => <div className={params.value == "Close" ? `${cascading.statuslabel} ${cascading.cancel}`: `${cascading.statuslabel} ${cascading.completed}`}>{params.value}</div>, },


  ];

  const rows = [
    { id: 0,sno:"1", symbol: "Loreum", size: 'Loreum', entryprice: 'Loreum', markprice: 'Loreum', liqprice: 'Loreum', marginratio: 'Loreum', margin: 'Loreum', pnl: 'Loreum', status: "Close" },
    { id: 1,sno:"2", symbol: "Loreum", size: 'Loreum', entryprice: 'Loreum', markprice: 'Loreum', liqprice: 'Loreum', marginratio: 'Loreum', margin: 'Loreum', pnl: 'Loreum', status: "Close" },
    { id: 2,sno:"3", symbol: "Loreum", size: 'Loreum', entryprice: 'Loreum', markprice: 'Loreum', liqprice: 'Loreum', marginratio: 'Loreum', margin: 'Loreum', pnl: 'Loreum', status: "Close" },


  ];
  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 1000,
    maxColumns: 4
  });
  const inputElement = useRef();
  


  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (

      <Pagination
        color="standard"
        shape="rounded"
        page={page + 1}
        // className={`${styles.pagecustom}`}
        count={pageCount}
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />

    );
  }



  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>

              <Pricecard />
              <div className={`${cascading.innercontent} ${cascading.airdrop} `}>
                <p className={`${cascading.pagehead} mb-4`}>Trading Airdrop</p>
                <div className={`${cascading.insidegrid} mb-4`}>
                  <div className={`${cascading.chartsec}`}>
                    <Tradechart />
                  
                  </div>
                  <div className={`${cascading.tradebtnsec}`}>
                      <button className={`${cascading.buybtn} btn`}>Buy</button>
                      <button className={`${cascading.sellbtn} btn`}>Sell</button>
                  </div>
                  {/* <Ordersection/> */}
                  <div className={`${cascading.coinsec}`}>
                    <div className={`${cascading.coin}`}>
                      <ul className={`${cascading.coinlist}`}>
                        {coins.map((e) => (
                          <li>
                            <div className={`${cascading.leftimgsec}`}>
                            <img src={flagimg} className={`img-fluid ${cascading.flagimg}`}/>
                              <img src={e.img} className={`img-fluid ${cascading.coinimg}`}/>
                              <p className={`${cascading.coinname}`}>{e.coinname}</p>
                            </div>

                            <p className={`${cascading.price}`}>{e.price}</p>
                          </li>
                        ))}

                      </ul>
                      <div className={`${cascading.btnsec}`}>
                        <button className={`${cascading.tradebtn} btn`} onClick={()=>setSettingmodal(true)}>Trade Settings</button>
                      </div>

                    </div>

                  </div>

                </div>
                <div id="styletable">
                    <DataGrid
                      ref={inputElement}
                      {...data}
                      disableColumnMenu
                      rows={rows}
                      sx={{
                        ...StyledDataGrid(),
                      }}
                      columns={columns}

                      components={{
                        Pagination: CustomPagination,
                      }}
                    // slots={{
                    //   footer: CustomPagination,
                    // }}



                    />
                  </div>


              </div>
               <Innerfooter/>

            </div>

          </Grid>
        </Grid>
      </div>
      {settingmodal && <Tradesettings show={settingmodal} onClose={() => setSettingmodal(false)} />}
    </>
  )
}

export default Airdrop