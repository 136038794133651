import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';

import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Pricecard from '../components/pricecard';
import { IoTrendingUpOutline } from 'react-icons/io5';
import Slider from '@mui/material/Slider';
import Innerfooter from '../common/innerfooter';
import Spotmodal from '../modals/spotmodal';

function Aisettings() {
  const [aitab, setAitab] = useState(true)
  const [margintab, setMargintab] = useState(true)
  const [sliderValue, setSliderValue] = useState("1");
  const [spotmodal,setSpotmodal]= useState(false)
  const marks = [
    { value: 1, label: '1x' },
    { value: 25, label: '25x' },
    { value: 50, label: '50x' },
    { value: 75, label: '75x' },
    { value: 100, label: '100x' },

  ];

  function valuetext(value) {
    return `${value}x`;
  }

  function valueLabelFormat(value) {
    return value + "x";
    // marks.findIndex((mark) => mark.value === value);
  }
  const handleChange = (event, newValue) => {
    let sliderchn = newValue.toString();
    setSliderValue(sliderchn);
  };
  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>

              <Pricecard />
              <div className={`${cascading.innercontent} ${cascading.aisettingspage} `}>

                <p className={`${cascading.pagehead}`}>Trading AI Setting</p>
                <p className={`${cascading.pagedesc}`}>Set items to control the performance of the AI you trade for</p>
                <div className={`row ${cascading.cardsec}`}>
                  <div className={`col-lg-4 mb-4`}>
                    <div className={`${cascading.settingscard} ${cascading.firstcard}`}>
                      <div className={`${cascading.topheadrow}`}>
                        <p className={`${cascading.cardhead}`}>Trading Type</p>
                        <p className={`${cascading.feesplan}`}>No Subscription fee</p>
                      </div>
                      <div className={`${cascading.aitabsec}`}>
                        <div className={aitab ? `${cascading.tabstyle} ${cascading.tabstyleactive}` : `${cascading.tabstyle}`} onClick={() => setAitab(true)}>
                          <p>Future Type</p>
                        </div>
                        <div className={!aitab ? `${cascading.tabstyle} ${cascading.tabstyleactive}` : `${cascading.tabstyle}`} onClick={() => setSpotmodal(true)}>
                          <p >Spot Trading</p>
                        </div>

                      </div>
                      <div className={`${cascading.optionsec} row`}>
                        <div className={`col-sm-6`}>
                          <ul className={`${cascading.ullist}`}>
                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className={`col-sm-6`}>
                          <ul className={`${cascading.ullist}`}>
                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className={`col-lg-4 mb-4`}>
                    <div className={`${cascading.settingscard} ${cascading.secondcard}`}>
                      <div className={`${cascading.topheadrow}`}>
                        <p className={`${cascading.cardhead}`}>Margin Mode</p>
                        <p className={`${cascading.feesplan}`}>No Subscription fee</p>
                      </div>
                      <div className={`${cascading.aitabsec}`}>
                        <div className={margintab ? `${cascading.tabstyle} ${cascading.tabstyleactive}` : `${cascading.tabstyle}`} onClick={() => setMargintab(true)}>
                          <p>Isolated</p>
                        </div>
                        <div className={!margintab ? `${cascading.tabstyle} ${cascading.tabstyleactive}` : `${cascading.tabstyle}`} onClick={() => setMargintab(false)}>
                          <p >Cross</p>
                        </div>

                      </div>
                      <div className={`${cascading.leveragesec} row mx-0`}>
                        <div className={`${cascading.label} col-lg-3`}>
                          <p>Leverage</p>
                        </div>
                        <div className={`${cascading.rangesec} col-lg-6`}>
                          <div className={`${cascading.inputsec}`}>
                            <div className={`form-group`}>
                              <label for="exampleInputEmail1">BTC/USDT</label>
                              <input type="text" className={`form-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={sliderValue} />

                            </div>
                          </div>
                          <div className={`${cascading.ranger}`}>
                            <Slider
                              aria-label="Restricted values"
                              // value={sliderValue}
                              sx={{
                                '& .MuiSlider-thumb': {
                                  color: "#000000",
                                  boxShadow: "0px 1px 3px 0px #935EF63D, 1px 1px 2px 0px #FFFFFF3D inset",
                                  width: "10px",
                                  height: "10px",
                                  border: "2px solid #DFC78B"
                                },
                                '& .MuiSlider-track': {
                                  color: "#DFC78B",
                                  height: '4px'

                                },
                                '& .MuiSlider-mark': {
                                  color: "#DFC78B",
                                  height: "6px",
                                  width: "2px",
                                  borderRadius: "0px",
                                  boxShadow: "0px 1px 3px 0px #935EF63D ,1px 1px 2px 0px #FFFFFF3D inset;"
                                },
                                '& .MuiSlider-markActive': {
                                  backgroundColor: "#DFC78B",
                                  height: "6px",
                                  width: "2px",
                                  borderRadius: "0px",
                                  boxShadow: " 0px 1px 3px 0px #935EF63D , 1px 1px 2px 0px #FFFFFF3D inset;"
                                },
                                '& .MuiSlider-markLabel': {
                                  color: "#7E7D82",
                                  fontSize: "10px"
                                },
                                '& .MuiSlider-markLabelActive': {
                                  color: "#FFFFFF",
                                  fontSize: "10px"
                                },


                                '& .MuiSlider-rail': {
                                  color: "#7D6B4861",
                                  height: '6px'
                                },
                                '& .MuiSlider-active': {
                                  color: "green"
                                }
                              }}
                              onChange={handleChange}
                              valueLabelFormat={valueLabelFormat}
                              getAriaValueText={valuetext}
                              valueLabelDisplay="auto"
                              marks={marks}
                              step={null}
                            />
                          </div>


                        </div>
                        <div className={`${cascading.btnsec} col-lg-3`}>
                          <button className={`${cascading.themebtn} btn`}>Confirm</button>
                        </div>

                      </div>
                      <div className={`${cascading.optionsec} row`}>
                        <div className={`col-sm-6`}>
                          <ul className={`${cascading.ullist}`}>

                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className={`col-sm-6`}>
                          <ul className={`${cascading.ullist}`}>
                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>

                          </ul>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className={`col-lg-4 mb-4`}>
                    <div className={`${cascading.settingscard} ${cascading.thirdcard}`}>
                      <div className={`${cascading.topheadrow}`}>
                        <p className={`${cascading.cardhead}`}>Trading Type</p>
                        <p className={`${cascading.feesplan}`}>No Subscription fee</p>
                      </div>
                      <div className={`${cascading.optionsec} row`}>
                        <div className={`col-sm-6`}>
                          <ul className={`${cascading.ullist}`}>
                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>


                          </ul>
                        </div>
                        <div className={`col-sm-6`}>
                          <ul className={`${cascading.ullist}`}>

                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className={`form-check`}>
                                <input className={`form-check-input`} type="checkbox" value="" id="flexCheckChecked" />
                                <label className={`form-chcek-label`} for="flexCheckChecked">
                                  BTC | USDT futures AI
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>

                      </div>
                      <div className={`${cascading.btmrowsec}`}>
                        <div className={`${cascading.ratingsec} mb-2`}>

                          <div className={`${cascading.bluebg}`}>
                            <p>Rate of return setting</p>
                          </div>
                          <div>
                            <input type="text" className={`form-control ${cascading.rateinput}`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" />
                          </div>
                          <div>
                            <button className={`btn ${cascading.applybtn}`}>Apply </button>
                          </div>


                        </div>
                        <div className={`${cascading.ratingsec}`}>

                          <div className={`${cascading.redbg}`}>
                            <p>Rate of return setting</p>
                          </div>
                          <div>
                            <input type="text" className={`form-control ${cascading.rateinput}`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" />
                          </div>
                          <div>
                            <button className={`btn ${cascading.applybtn}`}>Apply </button>
                          </div>


                        </div>
                        <div className={`${cascading.btmbtnsec}`}>
                          <button className={`btn ${cascading.cancelbtn}`}>Cancel settings </button>
                          <button className={`btn ${cascading.checkbtn}`}>Check settings </button>
                        </div>
                      </div>

                    </div>
                  </div>


                </div>
                <div className={`${cascading.notesec}`}>
                  <p>All AI's trading environments are different depending on the setting conditions. Please set it carefully and trade it.
                    You are solely responsible for any loss resulting from the trading setup.</p>
                </div>

              </div>
              <Innerfooter/>

            </div>

          </Grid>
        </Grid>
        {spotmodal && <Spotmodal show={spotmodal} onClose={() => setSpotmodal(false)} />}
      </div>
    </>
  )
}

export default Aisettings;