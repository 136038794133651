import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from '../assests/scss/withdraw.module.scss'
function Withdrawmodal(props) {
    return (
        <div>
            <Modal show={props.show} onHide={props.onClose} centered className={`${styles.withdrawmodal}`}>
                <Modal.Header closeButton className={`${styles.modalhead}`}>
                    <Modal.Title className={`${styles.modaltitle}`}>Withdrawal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className={`${styles.inputrow} mb-4`}>
                            <p className={`${styles.inputlabel}`}>USDT BALANCE</p>
                            <div className={`input-group`}>
                                <input type="text" className={`form-control`} placeholder="" />
                                <span className={`input-group-text`}>USDT</span>
                            </div>

                        </div>
                        <div className={`${styles.inputrow} mb-4`}>
                            <p className={`${styles.inputlabel}`}>WITHDRAW QUANTITY</p>
                            <div className={`input-group`}>
                                <input type="text" className={`form-control`} placeholder="" />
                                <span className={`input-group-text`}>USDT</span>
                            </div>

                        </div>
                        <div className={`${styles.inputrow} mb-4`}>
                            <p className={`${styles.inputlabel}`}>WITHDRAW ADDRESS</p>
                            <div className={`${styles.ordinp}`}>
                                <input type="text" className={`form-control`} placeholder=""value={"Txsdf0as2d1s2asdasdfasdd1asd54"} />
                     
                            </div>

                        </div>
                        <div className={`${styles.inputrow} mb-4`}>
                            <p className={`${styles.inputlabel}`}>WITHDRAW FEES</p>
                            <div className={`input-group`}>
                                <input type="text" className={`form-control`} placeholder="" />
                                <span className={`input-group-text`}>USDT</span>
                            </div>

                        </div>
                        <div className={`${styles.inputrow} mb-4`}>
                            <p className={`${styles.inputlabel}`}>BALANCE AFTER WITHDRAWAL</p>
                            <div className={`input-group`}>
                                <input type="text" className={`form-control`} placeholder="" />
                                <span className={`input-group-text`}>USDT</span>
                            </div>

                        </div>
                        <div className={`${styles.inputrow} mb-4`}>
                            <p className={`${styles.inputlabel}`}>EMAIL AUTHENTICATION</p>
                            <div className={`${styles.ordinp}`}>
                                <input type="text" className={`form-control`} placeholder="" />
                     
                            </div>

                        </div>
                        
                    </div>



                </Modal.Body>
                <Modal.Footer className={`${styles.modalfooter}`}>
                 <button className={`${styles.themebtn} btn`} onClick={props.onClose}>Withdrawal</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Withdrawmodal