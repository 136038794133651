import React, { useState, useEffect } from 'react';
import { Button, Container, Nav, NavDropdown, Navbar, Offcanvas } from 'react-bootstrap';
import logo from '../assests/images/logo.png'
import Switch from 'react-switch';
import { Link, NavLink } from 'react-router-dom';
import cascading from '../assests/scss/header.module.scss';
import Register from '../modals/register';
import Login from '../modals/login';
import { useSelector, useDispatch } from 'react-redux';
import { Statuses } from '../Redux/action';
import { toast } from 'react-toastify';
const Header = () => {
  const dispatch = useDispatch();
  
  const data = useSelector((state) => state.admin)
  const [menuOpen, setMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [registermodal, setRegistermodal] = useState(false);
  const [loginmodal, setLoginmodal] = useState(false);
  // const userdetail = useSelector((state) => state.admin)
  const toggleMenu = () => {
    // alert(1)
    const getWindowWidth = window.innerWidth
    console.log("windn", getWindowWidth, menuOpen)
    if (getWindowWidth < 992) {

      setMenuOpen(false)
    } else { setMenuOpen(false) }


  };
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleChange = checked => {
  
    if (checked ==  true){
      dispatch(Statuses({
        themeStatus: "light",
    }),
    
      );
      localStorage.setItem('theme', 'light');
      toast.success("Theme changed to Light")
    }
    else{
      dispatch(Statuses({
        themeStatus: "dark",
    }),
    
      );
      localStorage.setItem('theme', 'dark');
      toast.success("Theme changed to dark")
    }
  };
  return (
    <div className={`${cascading.headerpage}`}>

      <Navbar className={isScrolled ? `${cascading.navbar} ${cascading.navbaractive}` : `${cascading.navbar}`} key="lg" expand="lg" >
        <Container>
          <Navbar.Brand href="/"><img src={logo} className={`img-fluid ${cascading.logoimg}`} alt='images' /></Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} className={`${cascading.toggler} `} onClick={() => setMenuOpen(!menuOpen)} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end"
            restoreFocus={false}
            className={`${cascading.offcanvas} `}
            show={menuOpen}
            onHide={() => setMenuOpen(!menuOpen)}
          >
            <Offcanvas.Header closeButton >
              {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                <Link to="/" onClick={() => toggleMenu()}><img src={logo} className={`img-fluid ${cascading.logoimg}`} alt='images' /></Link>                            </Offcanvas.Title> */}
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className={`ms-auto align-items-center ${cascading.navlist}`}>
                {/* <NavLink to="#" className={`${cascading.navlinkstyle}`} activeClassName="active" onClick={() => toggleMenu()}>Markets</NavLink>
                <NavDropdown title="Dropdown" id="basic-nav-dropdown" className={`${cascading.dropstyle}`}>
                  <NavDropdown.Item href="#action/3.1">Trade</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              
                  <NavDropdown.Item href="#action/3.4">
                    Separated link
                  </NavDropdown.Item>
                </NavDropdown>
                <NavLink to="#" className={`${cascading.navlinkstyle}`} activeClassName="active" onClick={() => toggleMenu()}>Bots</NavLink>
                <NavLink to="#" className={`${cascading.navlinkstyle}`} activeClassName="active" onClick={() => toggleMenu()}>Earn</NavLink> */}
              <div  className={`${cascading.themeswitch}`}>
                      <span className={`${cascading.swtlabel}`}>Dark</span>
                      <Switch onChange={handleChange} checked={data.themeStatus == "light" ? true : false} 
                      handleDiameter={30}
                      borderRadius={6}
                      offColor="#2b2519"
                      onColor="#2b2519" 
                      offHandleColor="#dfc78b"
                      onHandleColor="#dfc78b"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      />
                      <span className={`${cascading.swtlabel}`}>Light</span>
                      </div>
                {data.loginStatus == "login" ?

                  <NavLink to="/community" className={`btn ${cascading.themebtn}`}>Dashboard</NavLink> :
                  <>
                    <Button className={`btn ${cascading.loginbtn}`} onClick={() => {setLoginmodal(true); setRegistermodal(false)}}>Login</Button>
                    <Button className={`btn  ${cascading.regbtn}`} onClick={() => {setLoginmodal(false); setRegistermodal(true)}}>Register</Button>
                  </>
                }
                


              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      {loginmodal && <Login show={loginmodal} onHide={() => setLoginmodal(false)} />}
      {registermodal && <Register show={registermodal} onHide={() => setRegistermodal(false)} />}

    </div>
  )
}

export default Header;