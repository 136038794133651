import React, { useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import Select from 'react-select';
import cascading from '../assests/scss/register.module.scss';
import { Button } from 'react-bootstrap';
const options = [
    { value: 'india', label: 'India' },
    { value: 'uk', label: 'UK' },
    { value: 'uae', label: 'UAE' },
];
function Register(props) {

    const [selectedOption, setSelectedOption] = useState(null);
    return (
        <div className={`${cascading.loginmodal}`}>
            <Offcanvas show={props.show} onHide={props.onHide} backdrop="static" placement='top' className={`${cascading.offcanvas}`}>
                <div className={`container`}>


                    <Offcanvas.Header closeButton>
                        <p className={`${cascading.head}`}>LAPARRAL JOIN MEMBERSHIP
                        </p>
                        <p className={`${cascading.desc}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </Offcanvas.Header>
                    <Offcanvas.Body className={`${cascading.bodyrow}`}>
                        <div className={`row`}>
                            <div className={`col-lg-6`}>
                                <div className={`row`}>
                                    <div className={`col-3  mb-3`}>
                                        <p className={`${cascading.labelname}`}>ID</p>
                                    </div>
                                    <div className={`col-9 mb-3`}>
                                        <input type="text" className={`form-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="ID" />
                                    </div>

                                </div>
                                <div className={`row`}>
                                    <div className={`col-3  mb-3`}>
                                        <p className={`${cascading.labelname}`}>Email</p>
                                    </div>
                                    <div className={`col-9 mb-3`}>
                                        <input type="email" className={`form-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" />
                                    </div>

                                </div>
                                <div className={`row`}>
                                    <div className={`col-3  mb-3`}>
                                        <p className={`${cascading.labelname}`}>Email Authentication Verfication</p>
                                    </div>
                                    <div className={`col-9 mb-3`}>
                                        <input type="text" className={`form-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" />
                                    </div>

                                </div>

                            </div>
                            <div className={`col-lg-6`}>
                                <div className={`row`}>
                                    <div className={`col-3  mb-3`}>
                                        <p className={`${cascading.labelname}`}>Country</p>
                                    </div>
                                    <div className={`col-9 mb-3`}>
                                        <div className={`${cascading.reactselect}`}>
                                            <Select
                                                className='reactselect_container'
                                                classNamePrefix="reactselect"
                                                defaultValue={selectedOption}
                                                onChange={setSelectedOption}
                                                options={options}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className={`row`}>
                                    <div className={`col-3  mb-3`}>
                                        <p className={`${cascading.labelname}`}>phone</p>
                                    </div>
                                    <div className={`col-9 mb-3`}>
                                        <input type="text" className={`form-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="phone" />
                                    </div>

                                </div>
                                <div className={`row`}>
                                    <div className={`col-3  mb-3`}>
                                        <p className={`${cascading.labelname}`}>SMS Authentication Verfication</p>
                                    </div>
                                    <div className={`col-9 mb-3`}>
                                        <input type="text" className={`form-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="sms" />
                                    </div>

                                </div>
                                <div className={`row`}>
                                    <div className={`col-3  mb-3`}>
                                        <p className={`${cascading.labelname}`}>Referral</p>
                                    </div>
                                    <div className={`col-9 mb-3`}>
                                        <input type="text" className={`form-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Referral" />
                                    </div>

                                </div>

                            </div>

                        </div>
                    </Offcanvas.Body>
                    <hr className={`${cascading.linehr}`} />
                    <div className={`row ${cascading.canfooter}`}>
                        <div className={`col-lg-6`}>
                            <div className={`form-check ${cascading.checksec}`}>
                                <input className={`form-check-input ${cascading.checkinput}`} type="checkbox" value="" id="flexCheckDefault" />
                                <label className={`form-check-label`} for="flexCheckDefault">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                </label>

                            </div>
                        </div>
                        <div className={`col-lg-6 ${cascading.btnsec}`}>
                                <Button className={`btn ${cascading.regbtn}`}>Join</Button>

                        </div>

                    </div>
                </div>
            </Offcanvas>
        </div>
    )
}

export default Register